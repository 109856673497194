import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import arrowImg from '../../assets/down-arrow.png';

const DropdownContainer = styled.div`
  position: relative;
  width: 100%;
  padding: 4px;
`;

const DropdownButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  width: 100%;
  border: none;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 0.10);
  cursor: pointer;
`;

const DropdownButtonImg = styled.img`
  width: 20px;
  height: 20px;
`;

const DropdownMenu = styled.ul`
  list-style: none;
  padding: 0;
  margin-top: -4px;
  margin-bottom: 4px;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  position: absolute;
  z-index: 1;
  width: ${({ menuWidth }) => menuWidth}px;
`;

const DropdownMenuItem = styled.li`
  padding: 8px;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
`;

const ImavenDropdown = ({ options, onSelect }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(options[0]);
    const dropdownRef = useRef(null);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionSelect = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
        onSelect(option);
    };

    const handleOutsideClick = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    return (
        <DropdownContainer ref={dropdownRef}>
            <DropdownButton onClick={toggleDropdown}>
                {selectedOption}
                <DropdownButtonImg src={arrowImg} alt="Dropdown Icon" />
            </DropdownButton>
            {isOpen && (
                <DropdownMenu menuWidth={dropdownRef.current.offsetWidth}>
                    {options.map((option, index) => (
                        <DropdownMenuItem
                            key={index}
                            onClick={() => handleOptionSelect(option)}>
                            {option}
                        </DropdownMenuItem>
                    ))}
                </DropdownMenu>
            )}
        </DropdownContainer>
    );
};

export default ImavenDropdown;