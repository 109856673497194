import React, { createContext, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

// Create a new context for Auth0.
const Auth0Context = createContext();

// Custom hook to use our Auth0 context. This provides a consistent way to access
// Auth0 data and methods throughout our app.
export const useAuth0Context = () => {
  return useContext(Auth0Context);
};

// This provider wraps the main part of our app to make Auth0's functionalities
// available throughout. It abstracts away the direct use of Auth0's hook.
export const Auth0ProviderWrapper = ({ children }) => {
  const auth0Data = useAuth0();

  return (
    <Auth0Context.Provider value={auth0Data}>
      {children}
    </Auth0Context.Provider>
  );
};
