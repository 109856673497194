import React, { useState } from 'react';
import styled from 'styled-components';
import ImavenCheckbox from '../Helpers/imaven-checkbox';
import ImavenDropdown from '../Helpers/imaven-dropdown';
import ImavenRadio from '../Helpers/imaven-radio';
import ImavenNumeric from '../Helpers/imaven-numeric';
import { useNavigate } from 'react-router-dom';
import EnhanceExp from './enhance-exp';
import RandomImage from './random-image'
import useAuthenticatedAxios from '../../interceptor/AuthenticatedAxios';
import { useConversation } from '../../providers/ConversationContext';

const QContiner = styled.div`
    // height:75vh;
    // width:90%;
    padding:5px;
`;

const Continer = styled.div`
    margin-top:10px;
    padding-left:13px;
`;

const MContiner = styled.div`
    margin-top:20px;

    @media (min-height: 667px) {
        min-height: 28vh;
    }

    @media (min-height: 668px) and (max-height:740px) {
        min-height: 33vh;
    }

    @media (min-height: 832px) and (max-height:844px) {
        min-height: 38vh;
    }
    
    @media (min-height: 845px) and (max-height:932px) {
        min-height: 40vh;
    }
`;

const BtnContiner = styled.div`
    padding:10px;
    // margin-top:50px;
`;

const NextBtn = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.3rem 1rem 0.3rem 1rem;
    background-color: #499EDC;
    color: #FFFFFF;
    border: none;
    border-radius: 30px;
    font-size: 0.8rem;
    height:45px;
    box-shadow: 1px 1px 8px rgba(86, 204, 242, 0.1);
    width: 100%; // 100% of the parent width
    float:right;
    transition: background-color 0.2s ease-in-out;

    ${props => props.disabled && `
        background-color: #bdc3c7;
        cursor: not-allowed;
  `}

`;

const StyledTextarea = styled.textarea`
  width: 300px; // adjust to your desired width
  height: 150px; // adjust to your desired height
  fill: #FFF;
  background-color: #FFF;
  filter: drop-shadow(1px 1px 8px rgba(0, 0, 0, 0.10));
  border: none;
  resize: none; // to prevent resizing by user
  padding: 10px; // optional for inner spacing
  font-size: 16px; // optional

    &:focus {
    outline: none;
    -webkit-tap-highlight-color: transparent;
  }

`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid lightgray;
  border-radius: 100px;
  padding: 0.5rem;
  margin: 0.5rem 0.5rem;
  flex-grow: 1;
  width:40%;
`;

const Input = styled.input`
  border: none;
  background-color: transparent;
  margin-left: 0.5rem;
  flex-grow: 1;
  width:100%;

  &:focus {
    outline: none;
    -webkit-tap-highlight-color: transparent;
  }

`;

const Question = ({ Question, nextQ }) => {

    const [selectedRadio, setSelectedOption] = useState("0");
    const [selectedCheckbox, setSelectedCheckbox] = useState([]);
    const [selectedDropdown, setSelectedDropdown] = useState("");
    const [selectedSlider, setSelectedSlider] = useState("");
    const [specifyChange, setSpecifyChange] = useState("");
    const [freeText, setFreeText] = useState("");
    const [disabledNext, setNextFlag] = useState(true);
    const [selectedNumeric, setSelectedNumeric] = useState(0);
    const [showEnhance, setShowEnhance] = useState(true);
    const [minRangeVal, setMinRangeVal] = useState();
    const [maxRangeVal, setMaxRangeVal] = useState();
    const authenticatedAxios = useAuthenticatedAxios();
    const { conversationId, setConversationId } = useConversation();

    const handleFreeTextChange = (event) => {
        setFreeText(event.target.value);
        if (freeText.length >= 1) {
            setNextFlag(false);
        } else {
            setNextFlag(true);
        }
    };

    const handleSelectedDropdown = (op) => {
        setSelectedDropdown(op);
        setNextFlag(false);
    }

    const handleOptionChange = (value) => {
        setSelectedOption(value);
        setNextFlag(false);
    };

    const handleSpecifyChange = (val) => {
        setSpecifyChange(val);
        setNextFlag(false);
    }

    const handleCheckboxChange = (value) => {
        setSelectedCheckbox(value);
        if (value.length >= 1) {
            setNextFlag(false);
        }
        else {
            setNextFlag(true);
        }

    }

    const handleSelectedSlider = (val) => {
        setSelectedSlider(val);
        setNextFlag(false);
    }

    const handleNumericChange = (val) => {
        setSelectedNumeric(val);
        if (val === 0) {
            setNextFlag(true);
        } else {
            setNextFlag(false);
        }
    }

    const clearData = () => {
        setSelectedOption("0");
        setSelectedCheckbox([]);
        setSelectedDropdown("");
        setSelectedSlider("");
        setSpecifyChange("");
        setFreeText("");
        setMaxRangeVal("");
        setMinRangeVal("");
        setNextFlag(true);

    }

    const navigate = useNavigate();

    const handleMinRangeChange = (val) => {
        setMinRangeVal(val.target.value);
        const tempFrom = parseInt(val.target.value);
        const tempTo = parseInt(maxRangeVal);

        if (tempTo && tempTo > tempFrom) {
            setNextFlag(false);
        } else if (tempTo && tempTo < tempFrom) {
            setNextFlag(true);
        }

    }
    // maxRangeVal,minRangeVal
    const handleMaxRangeChange = (val) => {
        setMaxRangeVal(val.target.value);
        const tempFrom = parseInt(minRangeVal);
        const tempTo = parseInt(val.target.value);
        if (tempFrom && tempFrom < tempTo) {
            setNextFlag(false);
        } else if (tempFrom && tempFrom > tempTo) {
            setNextFlag(true);
        }
    }

    const nextQustion = () => {
        setNextFlag(true);

        let selectedAnswer = "";
        switch (Question.qtype) {
            case "dropdown":
                selectedAnswer = selectedDropdown;
                break;
            case "range":
            case "slider":
                selectedAnswer = "from: " + minRangeVal + ", To: " + maxRangeVal;
                break;
            case "checkbox":
            case "multiplechoice":
            case "multichoice":
                selectedCheckbox.forEach(x => {
                    selectedAnswer += x + ", "
                });
                break;
            case "startquestions":
            case "singlechoice":
            case "radiobutton":
            case "assessfamiliarity":
                if (selectedRadio.includes("specify")) {
                    selectedAnswer = specifyChange
                } else {
                    selectedAnswer = selectedRadio;
                }
                break;
            case "summary":

            
                break;
            default:
                selectedAnswer = freeText;
                break;
        }
        clearData();
        nextQ(selectedAnswer);

    }

    const objectToString = (obj, parentKey = '') => {

        if (typeof obj === 'string') {
            return obj;
        }

        let result = '';
        for (const key in obj) {
            const newKey = parentKey ? `${parentKey}.${key}` : key;

            if (Array.isArray(obj[key])) {
                result += `${newKey}: ${obj[key].join(', ')}; `;
            } else if (typeof obj[key] === 'object' && obj[key] !== null) {
                result += objectToString(obj[key], newKey) + ' ';
            } else {
                result += `${newKey}: ${String(obj[key])}; `;
            }
        }

        return result.trim();
    }

    const showSummary = (val) => {
        setShowEnhance(false);
        let txt = "";
        txt = objectToString(Question.content);
        txt += Question.options.join(',');
        navigate('/summary', { state: { txt: txt } });
    }

    const sendData = async (data) => {
        try {
            if (freeText.length <= 0) {
                const response = await authenticatedAxios.post('/logui', data);
                if (response.status >= 200 && response.status < 300) {

                } else {
                    console.error('Unexpected status code:', response.status);
                }
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    let q = <div></div>
    let image = <div></div>
    let c = <div></div>
    // if (Question.options.length === 0 && Question.qtype !== 'summary') {
    //     // send report to aoi
    //     console.log("problam with: " + Question.messageId)
    //     sendData({ qType: Question.qtype, conversationId: conversationId, messageId: Question.messageId })
    //     Question.qtype = "";
    // }
    switch (Question.qtype) {
        case "dropdown":
            q = <ImavenDropdown options={Question.options} onSelect={handleSelectedDropdown}></ImavenDropdown>
            image = <RandomImage questionType="dropdown" />
            c = <span>{Question.content}</span>
            break;
        case "range":
        case "slider":
            // q = <ImavenSlider minValue={0} maxValue={50000}
            //     numberType={'number'} color={'rgb(73, 158, 220)'}
            //     defaultValue={1} onValueChange={handleSelectedSlider}></ImavenSlider>
            q = <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <InputWrapper>
                    <Input type="number" placeholder="From" value={minRangeVal} onChange={handleMinRangeChange} />
                </InputWrapper>
                <InputWrapper>
                    <Input type="number" placeholder="To" value={maxRangeVal} onChange={handleMaxRangeChange} />
                </InputWrapper>
            </div>
            image = <RandomImage questionType="scale" />
            c = <span>{Question.content}</span>
            break;
        case "checkbox":
        case "multiplechoice":
            q = <ImavenCheckbox options={Question.options} maxSelected={Question.options.length}
                onChange={handleCheckboxChange}></ImavenCheckbox>
            image = <RandomImage questionType="checkbox" />
            c = <span>{Question.content}</span>
            break;
        case "radiobutton":
        case "singlechoice":
        case "assessfamiliarity":
            const op = Question.options.map((key, index) => {
                return { value: key, label: key }
            });
            q = <ImavenRadio options={op} onChange={handleOptionChange} onSpecifyChange={handleSpecifyChange} selectedOption={selectedRadio}></ImavenRadio>

            image = <RandomImage questionType="radio" />
            c = <span>{Question.content}</span>
            break;
        case "recommendation":
        case "summary":
            q = <div style={{ height: "90%", zIndex: '1000', position: 'absolute', left: '0', width: '100%', top: '10vh', backgroundColor: 'white' }}>
                <EnhanceExp showSummary={showSummary} />
                {/* <div style={{ padding: "10px" }}>
                    <Title>Result</Title><br />
                    <SubTitle>My recommendations</SubTitle>
                    <p>{Question.content}</p>
                </div>} */}
            </div>

            break;
        case "numeric":
            q = <ImavenNumeric value={0} onChange={handleNumericChange}></ImavenNumeric>
            break;
        default:
            q = <div style={{ padding: "10px" }}>
                <div style={{ maxHeight: "300px", overflow: "auto" }}>
                    <span style={{ fontWeight: 'bold' }}>{Question.content}</span>
                </div>
                <div>
                    <ul>
                        {Question.options.map((key, index) => <li key={index}>{key}</li>)}
                    </ul>
                </div>
                <StyledTextarea value={freeText} onChange={handleFreeTextChange} style={{ marginTop: "30px" }} placeholder="Please specify details, ask for guidance, or express uncertainties."></StyledTextarea>
            </div>
            image = <RandomImage questionType="text" />
            // c = <span>{Question.content}</span>
            break;
    }

    return (
        <div style={{ width: "100%", display: "flex", flexDirection: "column", height: "100%", justifyContent: "space-around" }}>
            <QContiner>
                {image}

                {(Question.qtype !== "summary" || "recommendation") && <Continer>
                    <div>
                        {Question.header !== 'none' && <span>
                            {Question.header}
                        </span>}
                    </div>
                    <br />
                    <div>
                        {c !== 'none' && <span style={{ fontWeight: 'bold' }}>
                            {c}
                        </span>}
                    </div>
                </Continer>}

                <MContiner>
                    {q}
                    <br />
                    <Continer>
                        <div>
                            {Question.footer !== 'none' && <span>
                                {Question.footer}
                            </span>}
                        </div>
                    </Continer>
                </MContiner>

                {/* {(Question.qtype !== "summary" || "recommendation") && <Continer>
                    <div>
                        {Question.footer !== 'none' && <span>
                            {Question.footer}
                        </span>}
                    </div>
                </Continer>} */}

            </QContiner>

            {(Question.qtype !== "summary" || "recommendation") &&
                <BtnContiner>
                    <NextBtn disabled={disabledNext} onClick={nextQustion}>
                        Next
                    </NextBtn>
                </BtnContiner>}
        </div>
    )
}

export default Question;