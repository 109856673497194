import SearchIcon from '@mui/icons-material/Search';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ImavenCheap from '../Helpers/imaven-cheap'
import icon from '../../assets/chat-main-icon.svg';
import mic from '../../assets/microphone.svg';
import sendIcon from '../../assets/Send.svg';
import { useNavigate } from 'react-router-dom'
import ImavenLoading from '../Helpers/imaven-loading-icon'
import { useAuth0Context } from '../../auth/Auth0Context';
import useAuthenticatedAxios from '../../interceptor/AuthenticatedAxios';
import { useConversation } from '../../providers/ConversationContext';
import toast from 'react-hot-toast';
import Cookies from 'js-cookie';

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid lightgray;
  border-radius: 100px;
  padding: 0.5rem;
  margin: 0.5rem 0.5rem;
  flex-grow: 1;
  

  @media (min-width: 768px) {
    
  }
`;

const SearchInput = styled.input`
  border: none;
  background-color: transparent;
  font-family: Qanelas Soft;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: gray;
  margin-left: 0.5rem;
  flex-grow: 1;

  &:focus {
    outline: none;
    -webkit-tap-highlight-color: transparent;
  }

`;

const Page = styled.div`
    border-radius: 0px 0px 5px 5px;
    background: linear-gradient(180deg, #01BDFB 0.81%, #28A0F8 88.23%);
    height:50vh;
    width:100%;
    display:flex;
    justify-content:space-evenly;
    align-items:center;
    flex-direction: column;

    
`;

const MainIcon = styled.img`
`;

const ButtomPage = styled.div`
  display:flex;
  height: 50vh;
  flex-direction: column;
`;

const BlankPart = styled.div`
    height:60%;
`;

const ButtomPart = styled.div`
    height:40%;
`;

const SendMessageBtn = styled.div`
    width: 40px;
    height: 40px;
    background-color: #009BF3;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; 
    pointer-events: auto; 

    img {
     max-width: 100%;
     max-height: 100%;
     transition: opacity 0.8s ease;
    }

    ${props => props.disable && `
        opacity:0.3;
        cursor: no;
        pointer-events: none;    
    `}

`;

const TitleSpan = styled.span`
color: #FFF;
text-align: center;
font-family: Varela Round;
font-size: 48px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: uppercase;
`;

const SubTitleText = styled.a`
display: flex;
width: 275px;
flex-direction: column;
justify-content: center;
flex-shrink: 0;
color: #FFF;
text-align: justify;
font-family: Qanelas Soft;
font-size: 16px;
font-style: normal;
font-weight: 300;
line-height: 25px; /* 200% */
`;

const LoadingScreen = styled.div`
    position:fixed;
    z-index:1000;
    height:100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;

const StartChat = () => {
    const { isAuthenticated, loginWithRedirect } = useAuth0Context();

    const cheapList = ['Can you recommend a good ', 'I`m looking for a ', 'Can you help me ', 'I want to buy a '];
    const [selectedCheap, setSelectedCheap] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [imageSrc, setImageSrc] = useState(sendIcon);
    const [loading, setLoading] = useState(false);
    const [disableSend, setDisableSend] = useState(true);
    const authenticatedAxios = useAuthenticatedAxios();
    const { conversationId, setConversationId } = useConversation();

    // useEffect(() => {
    //    // const firstMessage = Cookies.get('firstMessage');
    //     if (firstMessage && isAuthenticated) {
    //         setSearchValue(firstMessage);
    //         //handleSearchChange(firstMessage);
    //         handleSendMessage(searchValue);
    //     }
    // }, [isAuthenticated]);

    const navigate = useNavigate();

    const handleSelectCheap = (cheap) => {
        setSelectedCheap(cheap);
        setSearchValue(cheap);
        setImageSrc(sendIcon);
        setDisableSend(false);


    };

    const handleSearchChange = (value) => {
        setSearchValue(value);
        if (value.length > 3) {
            setImageSrc(sendIcon);
            setDisableSend(false);
        }
        else
            setDisableSend(true);
    }

    const handleSendMessage = async () => {
        if (isAuthenticated) {
            setLoading(true);
            try {
                await postNewChat();
            } catch (error) {
                handleRequestError(error);
            } finally {
                setLoading(false);
            }
        }
        else {
            // Cookies.set('firstMessage', searchValue, { expires: 7 }); // Expires in 7 days
            loginWithRedirect()
        }
    }

    async function postNewChat() {
        // const firstMessage = Cookies.get('firstMessage');
        const response = await authenticatedAxios.post('/newchat', { content: searchValue });
        console.log('Data fetched successfully:', response.data);
        setConversationId(response.data);
        await postChat(response.data);
    }

    async function postChat(conversationId) {
        // const firstMessage = Cookies.get('firstMessage');
        Cookies.set('conversationId',conversationId);
        const responseChat = await authenticatedAxios.post('/chat', { content: searchValue, conversationId });
        // Cookies.remove('firstMessage');

        navigate('/QuestionGenerator', { state: { txt: responseChat.data } });
    }

    function handleRequestError(error) {
        console.error('Error fetching data:', error);
        toast.error(error.message || "An error occurred");
    }

    return (
        <div>
            {loading ?
                (<LoadingScreen >
                    <ImavenLoading type={'first'} />
                </LoadingScreen>) : <div />
            }
            <Page>
                <TitleSpan>IMAVEN</TitleSpan>
                <MainIcon src={icon} />
                <SubTitleText>You're a step away from expert product advice! Just let us know what you’re looking for (type or voice) and let IMaven do its magic.</SubTitleText>
            </Page>
            <ButtomPage>
                <BlankPart>
                </BlankPart>
                <ButtomPart>
                    <ImavenCheap cheapList={cheapList} onSelectCheap={handleSelectCheap}></ImavenCheap>
                    <div style={{ display: "flex", alignItems: "center", margin: "0 1rem" }}>
                        <SearchWrapper>
                            <SearchInput type="text" placeholder="Search..." value={searchValue} onChange={(e) => handleSearchChange(e.target.value)} />
                            <SearchIcon />
                        </SearchWrapper>
                        <SendMessageBtn onClick={handleSendMessage} disable={disableSend}>
                            <img src={imageSrc} alt="Button Image" />
                        </SendMessageBtn>
                    </div>
                </ButtomPart>
            </ButtomPage>
        </div>

    );
}

export default StartChat;