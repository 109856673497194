import React, { useState } from 'react';
import styled from 'styled-components';
import SelectedRadio from '../../assets/radio-selected.svg';
import UnsSelectedRadio from '../../assets/radio-unselected.svg';

const ImavenRadioGroup = styled.div`
  display: flex;
  flex-direction: column; /* Display in a column */
  align-items: flex-start; /* Align items to the left */
`;

const ImavenRadioButtonContainer = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;
  // width: 100%; /* Occupy full width of the container */
  justify-content: space-evenly;
`;

// const ImavenRadioGroup = styled.div`
//   display: flex;
//   // flex-wrap: wrap;
//   flex-direction:colum
// `;

// const ImavenRadioButtonContainer = styled.label`
//   display: flex;
//   align-items: center;
//   cursor: pointer;
//   margin-bottom: 10px;
//   justify-content: space-evenly;
// `;

const ImavenRadioButtonInput = styled.input`
  opacity: 0;
  cursor: pointer;
`;

const ImavenRadioButtonIcon = styled.svg`
  width: 25px;
  height: 25px;
  margin-right:15px;
  border-radius: 50%;
  position: relative;
  fill: ${({ checked }) => (checked ? '#499EDC' : '#DADADA')};

  &:after {
    content: '';
    position: absolute;
    display: ${({ checked }) => (checked ? 'block' : 'none')};
    // top: 4px;
    // left: 4px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #F8F8F8;
  }
`;

const ImavenRadioButtonImageContainer = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #499edc;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;

const ImavenRadioButtonImage = styled.img`
  width: 20px;
  height: 20px;
`;

const ImavenRadioButtonLabel = styled.span`
  font-size: 16px;
  margin-right: 10px;
`;

const StyledTextarea = styled.textarea`
  width: 300px; // adjust to your desired width
  height: 150px; // adjust to your desired height
  fill: #FFF;
  background-color: #FFF;
  filter: drop-shadow(1px 1px 8px rgba(0, 0, 0, 0.10));
  border: none;
  resize: none; // to prevent resizing by user
  padding: 10px; // optional for inner spacing
  font-size: 16px; // optional

      &:focus {
    outline: none;
    -webkit-tap-highlight-color: transparent;
  }

`;

const RadioImage = styled.img`
  width:20px;
  height:20px;
  margin-right: 10px;
`;

const ImavenRadioButton = ({ value, label, image, checked, onChange }) => {
  return (
    <ImavenRadioButtonContainer>
      <ImavenRadioButtonInput
        type="radio"
        value={value}
        checked={checked}
        onChange={onChange}
      />
      
      {checked && <RadioImage src={SelectedRadio}/>}
      {!checked && <RadioImage src={UnsSelectedRadio}/>}
  
      {image ? (
        <ImavenRadioButtonImageContainer>
          <ImavenRadioButtonImage src={image} alt={value} />
        </ImavenRadioButtonImageContainer>
      ) : (
        <ImavenRadioButtonLabel>{label}</ImavenRadioButtonLabel>
      )}
    </ImavenRadioButtonContainer>
  );
};

const ImavenRadio = ({ options, selectedOption, onChange, onSpecifyChange }) => {

  const [textAreaValue, setTextAreaValue] = useState('');
  const [selectedOptionTxt, setSelectedOptionTxt] = useState('');

  const handleOptionChange = (event) => {
    setSelectedOptionTxt(event.target.value.toString())
    onChange(event.target.value);
  };

  const handleTextAreaChange = (e) => {
    setTextAreaValue(e.target.value);
    onSpecifyChange(e.target.value);
  };

  return (
    <ImavenRadioGroup>
      {options.map((option) => (
        <ImavenRadioButton
          key={option.value}
          value={option.value}
          label={option.label}
          image={option.image}
          checked={selectedOption === option.value}
          onChange={handleOptionChange}
        />
      ))}

      {selectedOptionTxt.includes('specify') && (
        <div>
          <StyledTextarea
            value={textAreaValue}
            onChange={handleTextAreaChange}
            style={{ width: '300px', height: '100px', resize:"none",margin:"20px" }}
            placeholder="Comments..."
          />
        </div>
      )}

    </ImavenRadioGroup>
  );
};

export default ImavenRadio;
