import React from 'react';
import styled from 'styled-components';
import Close from '../../assets/recommendation/close.svg';
import QuestionMark from '../../assets/recommendation/question.svg';

const ModalBackdrop = styled.div`
     position: fixed;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
//   background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

const ModalContent = styled.div`
  position: relative;
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
  width:80%;
`;

const CloseButton = styled.img`
  position: absolute;
  top: 10px;
  right: 10px;
  width:24px;
  height:24px
  cursor: pointer;
`;

const Icon = styled.img`
    width: 28px;
    height: 28px;
    cursor: pointer;
`;

const ImavenModal = ({ children, isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <ModalBackdrop onClick={onClose}>
      <ModalContent onClick={e => e.stopPropagation()}>
        <div style={{ width: "100%", display: 'flex', justifyContent: 'space-between',marginBottom:'2vh' }}>
          <Icon src={QuestionMark} />
          <Icon onClick={onClose} src={Close} />
        </div>
        {children}
      </ModalContent>
    </ModalBackdrop>
  );
};

export default ImavenModal;
