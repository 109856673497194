// Sidebar.js
import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useAuth0Context } from '../../auth/Auth0Context'
import LogoutButton from '../../auth/logout-button'
import LoginButton from '../../auth/login-button'
import Line from '../../assets/side-bar/line.svg';
import Email from '../../assets/side-bar/email.svg';
import About from '../../assets/side-bar/about.svg';
import Logout from '../../assets/side-bar/logout.svg';
import Share from '../../assets/side-bar/share.svg';
import Tutorial from '../../assets/side-bar/tutorial.svg';
import Main from '../../assets/side-bar/main.svg';
import Logo from '../../assets/side-bar/logo.svg';
// import InstallPWA from './InstallPWA'

const SidebarContainer = styled.div`
  width: 350px;
  height: 70vh;
  position: fixed;
  top: 0;
  left: 0;
  background: #333;
  transition: left 0.3s;
  z-index: 10000;
  background-color:white;
`;

const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Translucent gray background */
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index:100;
`;

const Title = styled.div`
    padding-top: 7vh;
    flex-direction: column;
    align-items: center;
    width:100%;
    justify-content: center;
    display: flex;
    background: linear-gradient(78deg, #01BDFB 0.81%, #28A0F8 88.23%);
    height:20vh;
`;

const TitleText = styled.span`
    color: #FFF;
    text-align: center;
    font-family: Varela Round;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
`;

const ButtonsWrapper = styled.div`
    // height:15vh;
    display:flex;
    flex-direction: column;
    justify-content:space-between;
    margin-top:10px;
    margin-bottom: 20px;
`;

const TitleIcon = styled.div`
    background-image: url(${Logo});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width:100px;
    height:100px;
`;

const Icon = styled.div`
    background-image: url(${props => props.imgUrl});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width:20px;
    height:20px;
    margin-right:16px;
`;

const SideBarItem = styled.div`
    display:flex;
    align-items: center;
    text-align: center;
`;

const SideBarItemText = styled.span``;


const Sidebar = ({ close }) => {
    const navigate = useNavigate();
    const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0Context();

    const navigateToMain = () => {
        close();
        navigate('/', { replace: true })
    }

    const closeMenu = () => {
        close();
    }

    const navigateToGuide = () => {
        close();
        navigate('/guide', { replace: true })
    }

    const onShare = () => {
        navigator.share({ title: "Share link to app", url: "https://imavenai.com/", text: "Hey! I've been using iMaven for smart product recommendations and it's totally transformed the way I shop. With expert, AI-enhanced advice, iMaven helps me find exactly what I'm looking for, no matter what product I need. Thought you might enjoy it as well! Check it out here: https://imavenai.com. #SmartShopping #iMavenAI" })
    }

    const sendEmail = () => {
        const emailAddress = "support@imavenai.com";
        const mailtoLink = `mailto:${emailAddress}`;
        window.location.href = mailtoLink;
    }

    const goToAbout = () => {
        close();
        navigate('/about', { replace: true })
    }

    const divStyle = {
        backgroundImage: `url(${Line})`,
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: '1px',
        strokeWidth: '1px',
        // marginTop: "20px"
    };

    return (
        <LoadingOverlay>
            <SidebarContainer >
                <Title>
                    <TitleText>Imaven</TitleText>
                    <TitleIcon></TitleIcon>
                </Title>
                <div style={{ paddingLeft: "23px",marginTop:"3vh" }}>                    

                    <ButtonsWrapper>
                        <SideBarItem onClick={navigateToMain}>
                            <Icon imgUrl={Main} />
                            <SideBarItemText>
                                Go To Main
                            </SideBarItemText>
                        </SideBarItem>

                        <br />

                        <SideBarItem onClick={navigateToGuide}>
                            <Icon imgUrl={Tutorial} />
                            <SideBarItemText>
                                Tutorial
                            </SideBarItemText>
                        </SideBarItem>
                    </ButtonsWrapper>

                    <div style={divStyle} />

                    <ButtonsWrapper>
                        <SideBarItem onClick={onShare}>
                            <Icon imgUrl={Share} />
                            <SideBarItemText>
                                Share App
                            </SideBarItemText>
                        </SideBarItem>

                        <br />

                        <SideBarItem onClick={sendEmail}>
                            <Icon imgUrl={Email} />
                            <SideBarItemText>
                                Email Us
                            </SideBarItemText>
                        </SideBarItem>
                        
                        <br />

                        <SideBarItem onClick={goToAbout}>
                            <Icon imgUrl={About} />
                            <SideBarItemText>
                                About
                            </SideBarItemText>
                        </SideBarItem>

                        <SideBarItem>
                            {/* <InstallPWA /> */}
                        </SideBarItem>

                    </ButtonsWrapper>

                    <div style={divStyle} />

                    <ButtonsWrapper>
                        <SideBarItem>
                            {isAuthenticated ? <Icon imgUrl={Logout} /> : <Icon style={{ transform: "scaleX(-1)" }} imgUrl={Logout} />}
                            {isAuthenticated ? <LogoutButton /> : <LoginButton />}
                        </SideBarItem>
                    </ButtonsWrapper>
                </div>
            </SidebarContainer>
        </LoadingOverlay>

    );
};


export default Sidebar;
