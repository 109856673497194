import React from "react";
import { Routes, Route } from 'react-router-dom';
import QuestionGenerator from "../Questions/questions-generator"
import LandingPage from "../Landing/landing-page";
import StartChat from "../Landing/landing-start-chat";
import Guide from "../Guide/guide";
import ProtectedRoute from "./protected-route";
import Summary from "../Questions/summary";
import Feedback from "../feedback/feedback";
import About from "../about/about";
import Error from "../Layout/error";

export default function Router({ isAuthenticated }) {
  return (

    <div style={{ flex: 1, overflow: 'auto' }}>
      <section>
        <Routes>
          <Route path="/" element={<LandingPage />}></Route>
          <Route path="/guide" element={<Guide />}></Route>
          <Route path="/startchat" element={<StartChat />}></Route>
          <Route path="/QuestionGenerator" element={
            <ProtectedRoute>
              <QuestionGenerator />
            </ProtectedRoute>
          }></Route>
          <Route path="/summary" element={
            <Summary />
            // <ProtectedRoute>

            // </ProtectedRoute>
          }></Route>
          <Route path="/feedback/id/:convoId" element={
            <Feedback />
          }>
          </Route>

          <Route path="/about" element={
            <About />
          }>
          </Route>

          <Route path="/*" element={
            <Error />
          }>
          </Route>

        </Routes>
      </section>
    </div>
  );
}