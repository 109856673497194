import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!isAuthenticated) {
      loginWithRedirect();
    }

  }, [isAuthenticated, loginWithRedirect]);

  if (isAuthenticated) {
    return children;
  } else {
    loginWithRedirect();    // Optional: you could navigate or render some fallback UI here
     navigate('/');
    return null;
  }
};

export default ProtectedRoute;
