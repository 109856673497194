import React, { createContext, useContext, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Router from '../Router/router';
import { PopupContext } from '../Modals/PopupContext';
import useWindowSize from '../../utills/useWindowSize';
import toast from 'react-hot-toast';

export const MenuContext = createContext();

const ContentWrapper = styled.div`
  display: flex;
  //flex: 1;
  overflow: auto;
  height: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    height: 100%;

  }
`;

const Sidebar = styled.div`

@media (min-width: 768px){
  
}
  background-color: #499EDC;
  min-width: 10rem;
  width: 10%;
 // padding: 1rem;

  @media (max-width: 768px) {
    min-width: 0;
    width: 100%;
    height: 100%;
  }
`;

const MainContent = styled.div`
  flex: 1;
  // padding: 1rem 0rem 0rem 1rem;
  // height: 100vh;

  @media (max-width: 768px) {
    //padding: .3rem;
    height: 100%;

  }
`;

const Layout = ({ children, logoSrc, userName, userEmail, userAvatarSrc, isAuthenticated }) => {

  const size = useWindowSize();

  useEffect(() => {
    console.log(size.width)
    // Check if the toast has already been shown in this session
    if (!sessionStorage.getItem('toastShown') && window.innerWidth > 750) {
      // Show the toast
      toast(
        "This site is currently designed only for mobile device viewing. For the best experience, please access this site on a mobile device in portrait mode.",
        {
          duration: 6000,
          style: {
            borderRadius: '10px',
            // background: '#333333',
            // color: '#fff',
            width: '400px',
            height: '100px',
            justifyContent: 'start'

          },
          position: 'top-center'
        }
      );
      // Set a flag in session storage
      sessionStorage.setItem('toastShown', 'true');
    }
  }, []);
  const { openPopup } = useContext(PopupContext);
  function handlePopup(content) {
    // debugger;
    openPopup(content ? content : <div>blabla</div>);
  };

  {/* <Header
        userName={userName}
        userEmail={userEmail}
        userAvatarSrc={userAvatarSrc}
        isAuthenticated={isAuthenticated}
      />
      {size.width > 768 && <TopMenu
        categories={categories}
      />} */}

  return (
    <div>
      <ContentWrapper>
        <MainContent>
          <Router isAuthenticated={isAuthenticated}>
          </Router>
        </MainContent>
      </ContentWrapper>
      {/* {size.width > 750 && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent: 'center', height: '100vh' }}>
            <span style={{ fontWeight: 'bold', color: 'black' }}>This site is currently designed only for mobile device viewing. For the best experience, please access this site on a mobile device in portrait mode.</span>
          </div>} */}
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  logoSrc: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
  userAvatarSrc: PropTypes.string.isRequired
};

export default Layout;
