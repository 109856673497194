import React from 'react';
import styled from 'styled-components';



const StyledTextArea = styled.textarea`
  width: 100%;
  height:100%;
  padding: 10px;
  // padding-right: 30px; /* Extra padding for the SVG */
  background: #FFF;
  filter: drop-shadow(1px 1px 8px rgba(0, 0, 0, 0.10));
  border: none;
  resize: none; /* Optional: if you want to disable resizing */

  color: #B9B9B9;
  font-family: Qanelas Soft;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.48px;

  &:focus {
    outline: none;
  }
`;

const TextAreaContainer = styled.div`
  position: relative;
  width: 100%;
  height:100%;
`;

const SvgIcon = styled.svg`
  position: absolute;
  top: 10px;
  right: 0px;
  width: 19px;
  height: 19px;
`;

const ImavenTextarea = ({ placeholder, onChange }) => {
    return (
        <TextAreaContainer>
            <StyledTextArea placeholder={placeholder} onChange={e => onChange(e.target.value)} />
            <SvgIcon viewBox="0 0 19 19" fill="none">
                {/* SVG content here */}
                <g clip-path="url(#clip0_3514_2158)">
                    <path d="M16.5055 1.25254L16.948 1.69504C17.5578 2.30554 17.4655 3.38779 16.7403 4.11229L7.61505 13.2375L4.65855 14.319C4.2873 14.4555 3.9258 14.2785 3.8523 13.9253C3.82746 13.7971 3.83915 13.6646 3.88605 13.5428L4.98855 10.5608L14.0883 1.46029C14.8135 0.735786 15.8958 0.642786 16.5055 1.25329V1.25254ZM7.2168 2.16004C7.31529 2.16004 7.41281 2.17944 7.50381 2.21713C7.5948 2.25482 7.67748 2.31006 7.74713 2.37971C7.81677 2.44935 7.87202 2.53203 7.90971 2.62302C7.9474 2.71402 7.9668 2.81154 7.9668 2.91004C7.9668 3.00853 7.9474 3.10605 7.90971 3.19705C7.87202 3.28804 7.81677 3.37072 7.74713 3.44037C7.67748 3.51001 7.5948 3.56525 7.50381 3.60295C7.41281 3.64064 7.31529 3.66004 7.2168 3.66004H4.2168C3.81897 3.66004 3.43744 3.81807 3.15614 4.09938C2.87483 4.38068 2.7168 4.76221 2.7168 5.16004V14.16C2.7168 14.5579 2.87483 14.9394 3.15614 15.2207C3.43744 15.502 3.81897 15.66 4.2168 15.66H13.2168C13.6146 15.66 13.9962 15.502 14.2775 15.2207C14.5588 14.9394 14.7168 14.5579 14.7168 14.16V11.16C14.7168 10.9611 14.7958 10.7704 14.9365 10.6297C15.0771 10.4891 15.2679 10.41 15.4668 10.41C15.6657 10.41 15.8565 10.4891 15.9971 10.6297C16.1378 10.7704 16.2168 10.9611 16.2168 11.16V14.16C16.2168 14.9557 15.9007 15.7187 15.3381 16.2814C14.7755 16.844 14.0124 17.16 13.2168 17.16H4.2168C3.42115 17.16 2.65809 16.844 2.09548 16.2814C1.53287 15.7187 1.2168 14.9557 1.2168 14.16V5.16004C1.2168 4.36439 1.53287 3.60132 2.09548 3.03872C2.65809 2.47611 3.42115 2.16004 4.2168 2.16004H7.2168Z" fill="#B9B9B9" />
                </g>
                <defs>
                    <clipPath id="clip0_3514_2158">
                        <rect width="18" height="18" fill="white" transform="translate(0.164062 0.142578)" />
                    </clipPath>
                </defs>
            </SvgIcon>
        </TextAreaContainer>
    );
};

export default ImavenTextarea;
