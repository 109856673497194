import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import ErrorImg from '../../assets/errorImg.svg'
import ArrowImg from '../../assets/left-arrow.svg'

const BackgroundContiner = styled.div`
    background: linear-gradient(78deg, #01BDFB 0.81%, #28A0F8 88.23%);
    height:100vh;
    display:flex;
    align-items:center;
    flex-direction:column;
`;

const Title = styled.span`
    color: #FFF;
    text-align: center;
    font-family: Varela Round;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
`;

const SubTitle = styled.span`
    color: #FFF;
    text-align: center;
    font-family: Qanelas Soft;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 93.75% */
    letter-spacing: 0.96px;
    text-transform: uppercase;
`;

const MiniTitle = styled.span`
    color: #FFF;
    text-align: center;
    font-family: Qanelas Soft;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 125% */
    letter-spacing: 0.72px;
    text-transform: uppercase;
`;

const BackTitle = styled.span`
    color: #FFF;
    text-align: center;
    font-family: Qanelas Soft;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 187.5% */
    letter-spacing: 0.48px;
`;

const WhiteLine = styled.div`
    border-radius: 2px;
    background: #FFF;
    width: 40px;
    height: 2px;
`;

const BackBtn = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.3rem 1rem 0.3rem 1rem;
    background-color: #fff;
    color: #009BF3;
    border: none;
    border-radius: 30px;
    font-size: 0.8rem;
    height:45px;
    box-shadow: 1px 1px 8px rgba(86, 204, 242, 0.1);
    width: 80%; // 100% of the parent width
    float:right;
    transition: background-color 0.2s ease-in-out;
`;

const Icon = styled.img`
    width: 20px;
    height: 20px;
    margin-right:2vw;
`;

const Error = () => {
    const navigate = useNavigate();

    const divStyle = {
        backgroundImage: `url(${ErrorImg})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: '200px'
    };

    const navigateToMain = () => {
        navigate('/', { replace: true })
    }

    return (<BackgroundContiner>

        <div style={{ marginTop: '5vh' }}>
            <Title>imaven</Title>
        </div>

        <br />
        <br />
        <br />

        <div>
            <SubTitle>whoops!</SubTitle>
        </div>

        <br />
        <br />

        <div style={divStyle}></div>

        <br />

        <div>
            <MiniTitle>You’re lost..</MiniTitle>
        </div>

        <br />
        <br />


        <WhiteLine></WhiteLine>

        <br />
        <br />

        <BackTitle>Back to Home page</BackTitle>

        <br />
        <br />
        <br />
        <br />

        <BackBtn onClick={navigateToMain}>
            <Icon src={ArrowImg} />
            Back
        </BackBtn>

    </BackgroundContiner>);
}

export default Error;
