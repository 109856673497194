import './App.css';
import Layout from './components/Layout/layout';
import { Routes, BrowserRouter, Route, useNavigate } from 'react-router-dom';
import PopupProvider from './components/Modals/PopupContext'
import { useAuth0 } from '@auth0/auth0-react';
import { Auth0ProviderWrapper } from './auth/Auth0Context';
import { ConversationProvider } from './providers/ConversationContext';
import { Toaster } from 'react-hot-toast';
import Logo from './assets/logo512.svg';
import { useState, useEffect } from 'react';

function App() {
  const { isLoading, isAuthenticated, error, user, getAccessTokenSilently } =
    useAuth0();
  const [token, setToken] = useState(null);
  const [entress, setEntress] = useState(false);
  useEffect(() => {
    (async function getToken() {
      try {
        const tok = await getAccessTokenSilently();
        setToken(tok);
      } catch (error) {
        setToken(null);
      }
    })()
  }, [isAuthenticated]);

  const logoImage = {
    backgroundImage: `url(${Logo})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '100vh'
}

  return (
    <>
      {error && <p>Auth Error</p>}
      {!error && isLoading && <div style={logoImage}></div>}
      {((!error && isAuthenticated) || (!isAuthenticated && !isLoading)) &&
        <Auth0ProviderWrapper>
          <Toaster position="top-right" toastOptions={{
            // Default options for all toasts
            className: 'toast',
            style: {
              borderRadius: '10px',
              // background: '#333333',
              // color: '#fff',
              width: '300px',
              height: '50px',
              justifyContent: 'start'

            },
            // Customize individual toast types
            success: {
              iconTheme: {
                primary: '#4aed88',
                secondary: '#fff',
              },
            },
          }} />
          <PopupProvider>
            <ConversationProvider>
              <Layout
                logoSrc="/logo.png"
                userName={isAuthenticated ? user.nickname : "John Smith"}
                userEmail={isAuthenticated ? user.email : "john.smith@example.com"}
                userAvatarSrc={isAuthenticated ? user.picture : "john.smith@example.com"}
                isAuthenticated={isAuthenticated}
              >
              </Layout>
            </ConversationProvider>
          </PopupProvider>
        </Auth0ProviderWrapper>
      }
    </>
  );

}

export default App;
