import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import SpicalOffer from '../../assets/spical-offer.svg';
import useAuthenticatedAxios from '../../interceptor/AuthenticatedAxios';
import toast from 'react-hot-toast';
import { useConversation } from '../../providers/ConversationContext';
import RandomImage from './random-image';

const BlueBtn = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.3rem 1rem 0.3rem 1rem;
    background-color: #499EDC;
    color: #FFFFFF;
    border: none;
    border-radius: 30px;
    font-size: 0.8rem;
    height:80px;
    box-shadow: 1px 1px 8px rgba(86, 204, 242, 0.1);
    width: 130px;
    float:right;
    transition: background-color 0.2s ease-in-out;
`;

const GrayBtn = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.3rem 1rem 0.3rem 1rem;
    background-color: gray;
    color: #FFFFFF;
    border: none;
    border-radius: 30px;
    font-size: 0.8rem;
    height:80px;
    box-shadow: 1px 1px 8px rgba(86, 204, 242, 0.1);
    width: 130px;
    float:right;
    transition: background-color 0.2s ease-in-out;
`;

const Title = styled.span`
    color: #000;
    text-align: justify;
    font-family: Qanelas Soft;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.48px;
    width: 343px;
    flex-shrink: 0;
`;

const EnhanceExp = ({ showSummary }) => {
    const authenticatedAxios = useAuthenticatedAxios();
    const { conversationId, setConversationId } = useConversation();


    const iWantHuman = async () => {
        try {
            const response = await authenticatedAxios.post('/enhanceExp', { choice: true, conversationId: conversationId });
            if (response.status >= 200 && response.status < 300) {
                console.log('Data fetched successfully:', response.data);

            } else {
                toast.error(response.data || "An error occurred");
                console.error('Unexpected status code:', response.status);
            }
        } catch (error) {
            toast.error(error.message || "An error occurred");
            console.error('Error fetching data:', error);
        }

        showSummary(true);
    }

    const procced = () => {
        showSummary(true);
    }

    return (
        <div>
            <div style={{ width: "100vw", marginTop: "40px" }}> {/* Added marginTop here */}
                <RandomImage questionType="ask" />
            </div>
            <div style={{
                display: "flex", flexDirection: "column", height: "150px",
                justifyContent: "space-between", textAlign: 'center', marginTop: "60px", alignContent: 'center', alignItems: 'center' /* Increased marginTop here */
            }}>
                <Title style={{ textAlign: "center", fontWeight: 'bold' }}>Special offer!
                </Title>
                <Title style={{ textAlign: "center" }}>
                    {/* For just $5, you can have the AI response reviewed and refined by a professional human advisor. */}
                    Did you know that even the best AIs are occasionally limited? Want to ensure you receive the most accurate answer? Consider having a human specialist review your product recommendation. For just $1, get the assurance and expertise of a professional to confirm your choice.
                </Title>

            </div>
            <div style={{ display: "flex", justifyContent: 'space-evenly', marginTop: "80px" }}>
                <BlueBtn onClick={iWantHuman}>
                    I want a human advisor ($1)
                </BlueBtn>
                <GrayBtn onClick={procced}>
                    Thank you, I’m good.
                </GrayBtn>
            </div>
        </div>)
}

export default EnhanceExp;