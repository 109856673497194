import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import GuidePic from '../../assets/Guide-pic.svg';
import GuideStepOne from '../../assets/guide-step-one.svg';
import GuideStepTwo from '../../assets/guide-step-two.svg';
import GuideStepThree from '../../assets/guide-step-three.svg';
import Line from '../../assets/line.svg';
import arrow from '../../assets/arrow.svg';
import GuideStepOneImage from '../../assets/guide-step-ne-image.svg';
import GuideStepTwoImage from '../../assets/guide-step-two-image.svg';
import GuideStepThreeImage from '../../assets/guide-step-three-image.svg';
import { useNavigate } from 'react-router-dom';
import video from '../../assets/video/guide-video.mp4';
import HamburgerButton from '../Helpers/hamburger-button';
import Sidebar from '../Layout/Side-Menu';

// Styled Components
const CarouselContainer = styled.div`
  width: 100%;
  height: 80vh;
  max-width: 600px;
  overflow: hidden;
`;

const PagesContainer = styled.div`
  display: flex;
  transition: transform 0.3s ease;
  height:100%;
`;

const Page = styled.div`
  min-width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  flex-direction: column;
  overflow-y:auto;
`;

const IndicatorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const Indicator = styled.div`
  width: 25px;
  height: 5px;
  background-color: ${({ active }) => (active ? '#009BF3' : '#D9D9D9;')};
  margin: 0 5px;
  border-radius: 3px;
`;

const HamburgerButtonWrapper = styled.div`
    position: absolute;
    left: 25px;
    z-index: 1000;
    top: 3vh;
`;

const TopPage = styled.div`
    background: linear-gradient(180deg, #01BDFB 0%, #0382F6 100%);
    height:10vh;
    width:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction: column;
`;

const TitleSpan = styled.span`
    color: #FFF;
    text-align: center;
    font-family: Varela Round;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
`;

const PageOneTitle = styled.span`
    font-size: 30px;
    color: #009bf3;
    text-align: center;
    font-weight: 500;
    line-height: 24px;
`;

const PageThreeTitle = styled.span`
    color: #323233;
    text-align: center;
    font-family: Qanelas Soft;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 160% */
    letter-spacing: 0.6px;
    display: flex;
    width: 238px;
    height: 60px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
`;

const PageFourTitle = styled.span`
    display: flex;
    width: 238px;
    height: 60px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;

    color: #323233;
    text-align: center;
    font-family: Qanelas Soft;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 160% */
    letter-spacing: 0.6px;
`;

const PageOneSubTitle = styled.span`
    color: #323233;
    text-align: center;
    font-family: Qanelas Soft;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
    display: flex;
    width: 238px;
    height: 49px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
`

const PageOneWelcomMessage = styled.span`
    color: #323233;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-feature-settings: 'fina' on, 'sinf' on, 'numr' on, 'rvrn' on, 'liga' off;
    font-family: Qanelas Soft;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 200% */
    letter-spacing: 0.48px;
    text-transform: uppercase;
    display: flex;
    width: 342px;
    height: 32px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
`

const PageOneMessage = styled.span`
    color: #323233;
    text-align: center;
    font-family: Qanelas Soft;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 200% */
    letter-spacing: 0.48px;
    display: flex;
    width: 299px;
    height: 91px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
`;

const ExpandContainer = styled.div`
  width: 100%;
  height: 100%;
  display:flex;
  justify-content:center;
  flex-direction: column;
  background-color:#FFF;
  position: relative; // Set position to relative to contain the absolute positioned children
`;

const Square = styled.div`
  height: 30%;
  margin: 8px;
  background-color: white;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius:15px;
  fill: #FFF;
  filter: drop-shadow(1px 1px 8px rgba(0, 0, 0, 0.10));
`;

const ArrowButton = styled.button`
  position: absolute;
  right: 0;
  top: ${({ isExpanded }) => (isExpanded ? '0' : '0')}; // Adjust the top position based on isExpanded prop
  transform: translateY(${({ isExpanded }) => (isExpanded ? '0' : '-50%')});
  transform: rotate(${({ isExpanded }) => (isExpanded ? '-90deg' : '0')});;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 10px;
`;

const ArrowButtonUp = styled.button`
  position: absolute;
  right: 0;
  top:0;
  transform: rotate(-90deg);
  cursor: pointer;
  padding: 10px;
`;

const ExpandedSquare = styled(Square)`
  width: 100%;
  min-height: 700px;
  position: absolute; // Position absolute to overlay the container
  top: 0;
  left: 0;
  margin: 0;
  z-index:101;
  overflow-y:auto;
`;

const Icon = styled.div`
  font-size: 2rem;
`;

const Title = styled.h3`
  font-size: 1.5rem;
  color: #009BF3;
  margin: 10px 0;
`;

const Text = styled.p`
  font-size: 1rem;
  padding: 0 25px;
`;

const SquareContent = styled.div`
  text-align: center;
`;

const ExpandedContent = styled.div`
  padding: 20px;
  text-align: left;
  display:flex;
  justify-content:center;
  flex-direction: column;
  align-items:center;
  height:80%;
`;

const OrderedList = styled.ol`
  list-style-type: none; // Remove default list style
`;

const ListItem = styled.li`
    position: relative;
    margin-bottom: 10px;
    color: #323233;
    leading-trim: both;
    text-edge: cap;
    font-family: Qanelas Soft;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 175% */
    letter-spacing: 0.48px;

  &::before {
    content: '✔'; // Use checkmark as content
    position: absolute;
    left: -20px; // Position the checkmark to the left of the list item
    color: #009BF3; // Choose a color for the checkmark
  }
`;

const StyledVideo = styled.video`
  border-radius: 10px;
  width: 100%;
  height: auto;
`;

const Footer = styled.span`
    color: #323233;
    text-align: center;
    font-family: Qanelas Soft;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
`

const BtnContiner = styled.div`
    padding:10px;
    width:100%;
    // margin-top:2vh;
`;

const StartBtn = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.3rem 1rem 0.3rem 1rem;
    background-color: #499EDC;
    color: #FFFFFF;
    border: none;
    border-radius: 30px;
    font-size: 0.8rem;
    height:45px;
    box-shadow: 1px 1px 8px rgba(86, 204, 242, 0.1);
    width: 100%; // 100% of the parent width
    transition: background-color 0.2s ease-in-out;
`;

const ExpandComponent = ({ arrowClicked }) => {
    const [expandedIndex, setExpandedIndex] = useState(null);
    const renderSquare = (content, index) => {
        const isExpanded = index === expandedIndex;
        const SquareComponent = isExpanded ? ExpandedSquare : Square;

        const clickArrow = () => {
            setExpandedIndex(isExpanded ? null : index)
            arrowClicked(isExpanded);
        }

        return (
            <SquareComponent key={index}>
                {isExpanded ? content.expandedHtmlContent : (
                    <SquareContent>
                        <Icon>{content.icon}</Icon>
                        <Title>{content.title}</Title>
                        <Text>{content.text}</Text>
                    </SquareContent>
                )}
                <ArrowButton isExpanded={isExpanded} onClick={clickArrow}>
                    <img src={arrow} alt='arrow' />
                </ArrowButton>

            </SquareComponent>
        );
    };

    return (
        <ExpandContainer>
            {[
                {
                    icon: <img src={GuideStepOne} alt='option 1' />,
                    title: 'Step 1:',
                    text: 'Describe, in your own words, what you are looking for',
                    expandedHtmlContent: (
                        <ExpandedContent>
                            <PageOneTitle style={{ marginBottom: "20px" }}>Step 1:</PageOneTitle>
                            <img src={GuideStepOneImage} />
                            <PageOneWelcomMessage>Initiate the Chat</PageOneWelcomMessage>
                            <OrderedList>
                                <ListItem>Start by typing in what type of product you are looking for</ListItem>
                                <ListItem>Not sure what to answer? Make your best guess. We'll take your preferences into account.</ListItem>
                                <ListItem>Restart the process anytime to get recommendations for a different product.</ListItem>
                            </OrderedList>
                        </ExpandedContent>
                    )
                },
                {
                    icon: <img src={GuideStepTwo} alt='option 2' />,
                    title: 'Step 2:',
                    text: 'Answer a few simple questions',
                    expandedHtmlContent: (
                        <ExpandedContent>
                            <PageOneTitle style={{ marginBottom: "30px" }}>Step 2:</PageOneTitle>
                            <img style={{ width: "165px" }} src={GuideStepTwoImage} />
                            <PageOneWelcomMessage style={{ marginTop: "30px" }}>Answer the System-Generated Questions</PageOneWelcomMessage>

                            <OrderedList>
                                <ListItem>To provide you with the most relevant product recommendation, our system will generate a series of questions.</ListItem>
                                <ListItem>Take your time and answer each question to the best of your ability. The accuracy of our recommendation is directly related to the information you provide.</ListItem>
                                <ListItem>For example: If you've mentioned you want a laptop, the system might ask: "What will you primarily use the laptop for? Gaming, work, graphic design, or something else?"</ListItem>
                            </OrderedList>
                        </ExpandedContent>
                    )
                },
                {
                    icon: <img src={GuideStepThree} alt='option 3' />,
                    title: 'Step 3:',
                    text: 'Get a recommendation for the product that best suits you',
                    expandedHtmlContent: (
                        <ExpandedContent>
                            <PageOneTitle style={{ marginBottom: "20px" }}>Step 3:</PageOneTitle>
                            <img style={{ width: "200px" }} src={GuideStepThreeImage} />
                            <PageOneWelcomMessage style={{ marginTop: "30px" }}>Review Your Personalized Product Recommendation</PageOneWelcomMessage>
                            <OrderedList>
                                <ListItem>Imaven’s system will review all your answers as well as the latest information about relevant products, such as reviews, technical specs, pricing, and professional feedback</ListItem>
                                <ListItem>The system will select the best product for your needs as well as provide you with information on where to best purchase that product.</ListItem>
                                <ListItem>You can also have the recommendation reviewed by one of our human advisors (available in the premium version only)</ListItem>
                            </OrderedList>
                        </ExpandedContent>
                    )
                }
            ].map((content, index) => renderSquare(content, index))}
        </ExpandContainer>
    );
};

const Guide = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [startX, setStartX] = useState(0);
    const carouselRef = useRef(null);
    const [isExpanded, setExpanded] = useState(true);
    const [isOpen, setIsOpen] = useState(false);

    const handleTouchStart = (e) => {
        setStartX(e.touches[0].clientX);
    };

    const handleTouchEnd = (e) => {
        const difference = startX - e.changedTouches[0].clientX;
        setExpanded(true);

        if (difference > 50 && activeIndex < pages.length - 1) {
            setActiveIndex((prev) => prev + 1);
        } else if (difference < -50 && activeIndex > 0) {
            setActiveIndex((prev) => prev - 1);
        }
    };

    const navigate = useNavigate();

    const moveToStratChat = () => {
        navigate('/startchat', { replace: true })
    }

    const pages = [
        <Page key="1">
            <div style={{ padding: "10px", display: "flex", flexDirection: "column", alignItems: "center" }}>
                <PageOneTitle style={{ marginTop: "30px" }}>User Guide:</PageOneTitle>
                <PageOneSubTitle style={{ marginTop: "30px", marginBottom: "30px" }}>How To Find The Best Product Match For You</PageOneSubTitle>
                <img src={GuidePic} alt='page 1' />
                <PageOneWelcomMessage style={{ marginTop: "30px" }}>Welcome to IMaven</PageOneWelcomMessage>
                <PageOneMessage style={{ marginTop: "30px" }}>Our mission is to help you find the perfect product based on your specific needs. Follow the steps below to receive a personalized product recommendation:</PageOneMessage>
            </div>
        </Page>,
        <Page key="2"><ExpandComponent arrowClicked={(val) => { setExpanded(val) }} /></Page>,
        <Page key="3">
            <div style={{ padding: "10px", display: "flex", flexDirection: "column", alignItems: "center" }}>
                <PageThreeTitle style={{ marginTop: "30px" }}>How To Get The Best Product Recommendation:</PageThreeTitle>
                <OrderedList>
                    <ListItem>Be as specific as possible when answering questions.</ListItem>
                    <ListItem>If unsure about a question, provide your best guess or preferences.</ListItem>
                    <ListItem>You can always restart the process if you'd like to get recommendations for another product.</ListItem>
                </OrderedList>
                <img src={Line} alt='page 3' />
                <div style={{ marginTop: "30px" }}>
                    <StyledVideo controls>
                        <source src={video} type="video/mp4" />
                        Sorry, your browser doesn't support videos.
                    </StyledVideo>
                </div>
                <BtnContiner>
                    <StartBtn onClick={moveToStratChat}>Start</StartBtn>
                </BtnContiner>
                {/* <PageOneWelcomMessage style={{ marginTop: "30px" }}>Thank you for using Imaven!</PageOneWelcomMessage>
                <PageOneMessage style={{ marginTop: "10px" }}>We hope to make your shopping experience seamless and tailored to your exact needs.</PageOneMessage> */}
            </div>
        </Page>,
        // <Page key="4">
        //     <div style={{ padding: "10px", display: "flex", flexDirection: "column", alignItems: "center" }}>
        //         <PageFourTitle style={{ marginTop: "30px" }}>The Video Tutorial on how to use Imaven:</PageFourTitle>
        //         <div style={{ marginTop: "30px" }}>
        //             <StyledVideo controls>
        //                 <source src={video} type="video/mp4" />
        //                 Sorry, your browser doesn't support videos.
        //             </StyledVideo>
        //         </div>
        //     </div>
        // </Page>
    ];

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    }

    return (
        <div style={{ height: "100vh" }}>
            {isOpen && <Sidebar close={toggleSidebar} />}
            <div style={{ height: isExpanded ? "90%" : "90%" }}>
                <TopPage>
                    <HamburgerButtonWrapper>
                        <HamburgerButton isOpen={isOpen} toggle={toggleSidebar} ></HamburgerButton>
                    </HamburgerButtonWrapper>
                    <TitleSpan>IMAVEN </TitleSpan>
                </TopPage>
                <CarouselContainer
                    ref={carouselRef}
                    onTouchStart={handleTouchStart}
                    onTouchEnd={handleTouchEnd}
                >
                    <PagesContainer style={{ transform: `translateX(-${activeIndex * 100}%)` }}>
                        {pages}
                    </PagesContainer>
                </CarouselContainer>
            </div>
            <div style={{ height: "10%", display: "flex", justifyContent: "space-evenly", flexDirection: "column" }}>
                {isExpanded && <IndicatorContainer>
                    {pages.map((_, index) => (
                        <Indicator key={index} active={index === activeIndex} />
                    ))}
                </IndicatorContainer>}
                <div style={{ height: "1px", backgroundColor: "#eee" }}></div>
                <div style={{
                    textAlign: "center", display: "flex",
                    justifyContent: "center", alignItems: "center",
                    alignContent: "center"
                }}>
                    <Footer>©Imaven International Ltd 2023. All rights reserved.</Footer>
                </div>
            </div>
        </div>
    );
};

export default Guide