import React, { useState, useRef, useEffect } from 'react';
import './imaven-loading-icon-css.css'

const ImavenLoading = ({ type }) => {
    const canvasRef = useRef(null);
    const yellowRef = useRef(null);
    const blueRef = useRef(null);
    const magentaRef = useRef(null);
    const redRef = useRef(null);
    const cyanRef = useRef(null);
    const greenRef = useRef(null);
    const messages = {
        'none':['','',''],
        'first': ['Got it! Preparing a set of questions to understand exactly what you are looking for.', 'Just a moment, setting up to ensure we cover all your needs.', 'Making sure we ask the right questions... Almost ready!'],
        'middle': ['Thank you for your responses! Analyzing your answers...', 'Still with you. Gathering insights from your answers...', 'Almost done! Just ensuring we have everything to find your perfect product.'],
        'last': ['Analyzing your answers to find the best product for you...', 'Working hard to match your needs with the perfect product...', 'Your ideal product is within reach... Finalizing the search!']
    };
    const [message, setMessage] = useState(messages[type][0]);
    let wakeLock = null;



    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");

        const squares = [
            yellowRef.current,
            blueRef.current,
            magentaRef.current,
            redRef.current,
            cyanRef.current,
            greenRef.current
        ];

        const drawLines = () => {
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            const connections = [
                [squares[0], squares[4]],
                [squares[1], squares[4]],
                [squares[2], squares[4]],
                [squares[3], squares[4]],
                [squares[4], squares[5]],
                [squares[0], squares[5]],
                [squares[5], squares[1]],
                [squares[1], squares[2]],
                [squares[2], squares[3]],
                [squares[3], squares[0]],
                [squares[0], squares[1]],
                [squares[1], squares[3]],
                [squares[2], squares[5]],
                [squares[5], squares[3]],
            ];

            for (let i = 0; i < connections.length; i++) {
                const startSquare = connections[i][0];
                const endSquare = connections[i][1];

                const startX = startSquare.offsetLeft + (startSquare.offsetWidth / 2);
                const startY = startSquare.offsetTop + (startSquare.offsetHeight / 2);
                const endX = endSquare.offsetLeft + (endSquare.offsetWidth / 2);
                const endY = endSquare.offsetTop + (endSquare.offsetHeight / 2);

                const startColor = getComputedStyle(startSquare).getPropertyValue('background-color');
                const endColor = getComputedStyle(endSquare).getPropertyValue('background-color');

                const defaultColor = "#000";
                const gradient = ctx.createLinearGradient(startX, startY, endX, endY);
                gradient.addColorStop(0, startColor || defaultColor);
                gradient.addColorStop(1, endColor || defaultColor);

                ctx.beginPath();
                ctx.moveTo(startX, startY);
                ctx.lineTo(endX, endY);
                ctx.strokeStyle = gradient;
                ctx.lineWidth = 2;
                ctx.stroke();
            }

            requestAnimationFrame(drawLines);
        }

        const requestWakeLock = async () => {
            if ('wakeLock' in navigator) {
                try {
                    wakeLock = await navigator.wakeLock.request('screen');
                    console.log('Wake Lock is active');
                } catch (err) {
                    console.error(`Failed to acquire wake lock: ${err.name}, ${err.message}`);
                }
            } else {
                console.log('Wake Lock API not supported in this browser');
            }
        };

        requestWakeLock();
        drawLines();

        // Change message after 10 seconds
        const timer1 = setTimeout(() => {
            setMessage(messages[type][1]);
        }, 20000); // 10 seconds

        // Change message after 30 seconds
        const timer2 = setTimeout(() => {
            setMessage(messages[type][2]);
        }, 40000); // 30 seconds


        return () => {
            wakeLock?.release().then(() => {
                console.log('Wake Lock was released');
            });
            clearTimeout(timer1);
            clearTimeout(timer2);
        };

    }, []);

    return (
        <div className="center-body">
            <div className="loader-square-53">
                <canvas ref={canvasRef} width="300" height="300" style={{ position: 'absolute', top: 0, left: 0, zIndex: 0 }}></canvas>
                <div className="square sl-53-yellow" ref={yellowRef}></div>
                <div className="square sl-53-blue" ref={blueRef}></div>
                <div className="square sl-53-magenta" ref={magentaRef}></div>
                <div className="square sl-53-red" ref={redRef}></div>
                <div className="square sl-53-cyan" ref={cyanRef}></div>
                <div className="square sl-53-green" ref={greenRef}></div>
            </div>
            <div style={{ marginTop: "3vh", textAlign: 'center' }}>
                <span style={{ textAlign: "center", fontWeight: "bold", color: "#2069bd" }}>{message}</span>

            </div>
        </div>
    );

}

export default ImavenLoading;
