import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ImavenIcon from '../../assets/icon';
import { useNavigate } from 'react-router-dom'
import Sidebar from '../Layout/Side-Menu';
import HamburgerButton from '../Helpers/hamburger-button';
import { useAuth0Context } from '../../auth/Auth0Context';
import Cookies from 'js-cookie';

const Page = styled.div`
    background: linear-gradient(180deg, #01BDFB 0%, #0382F6 100%);
    height:100vh;
    width:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction: column; 
`;

const Title = styled.span`
    color: #FFF;
    text-align: center;
    font-family: Varela Round;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
`;

const SubTitle = styled.span`
    color: #FFF;
    text-align: center;
    font-family: Varela Round;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
`;

const Text = styled.p`
    color: #FFF;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: Qanelas Soft;
    font-feature-settings: 'fina' on, 'sinf' on, 'numr' on, 'rvrn' on, 'liga' off;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 200% */
    padding:37px;
`;

const IconWrapper = styled.div`
    width:174px;
    height: 185px;
`;

const LandingPage = () => {
    const navigate = useNavigate();
    const { isAuthenticated, loginWithRedirect } = useAuth0Context();
    
    useEffect(() => {
        try {
            const started = Cookies.get('started');
            if (isAuthenticated, started) {
                Cookies.remove('started');
                moveToStratChat();
            }
        }
        catch {

        }

    }, [isAuthenticated]);

    const [isOpen, setIsOpen] = useState(false);

    const moveToStratChat = () => {
        if (isAuthenticated) {
            Cookies.remove('started');
            navigate('/startchat')
        }
        else {
            Cookies.set('started', 'true');
            loginWithRedirect();
        }
        //navigate('/startchat')
    }
    const moveToGuide = () => {
        navigate('/guide')
    }

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    }

    return (
        <Page>
            <div style={{ position: 'absolute', left: "25px", zIndex: '10000', top: "30px" }}>
                <HamburgerButton isOpen={isOpen} toggle={toggleSidebar} ></HamburgerButton>
            </div>

            {isOpen && <Sidebar close={toggleSidebar} />}

            <IconWrapper>
                <ImavenIcon animate={false}></ImavenIcon>
            </IconWrapper>
            {/* {isAuthenticated ? <LogoutButton /> : <LoginButton />} */}

            <Title>
                Welcome to
            </Title>
            <SubTitle>
                Imaven
            </SubTitle>
            <Text>
                IMaven will help you find the right product for you,
                matching your exact needs and budget.
                IMaven uses millions of product recommendations from specialists in
                combination with advanced AI to eliminate the guesswork or decision
                fatigue from your shopping journey. Let's get started.
            </Text>

            <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="271" height="55" viewBox="0 0 271 55" fill="none" onClick={moveToStratChat}>
                    <path d="M1 27.5C1 12.8645 12.8645 1 27.5 1H243.5C258.136 1 270 12.8645 270 27.5C270 42.1355 258.136 54 243.5 54H27.5C12.8645 54 1 42.1355 1 27.5Z" fill="#D9D9D9" fillOpacity="0.16" stroke="white" strokeWidth="1.5" />
                    <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fontFamily="Arial" fontSize="20" fill="white">START</text>
                </svg>
            </div>

            <div style={{ marginTop: "50px" }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none" onClick={moveToGuide}>
                    <circle opacity="0.2" cx="30" cy="30" r="30" transform="rotate(90 30 30)" fill="white" />
                    <circle opacity="0.16" cx="30" cy="30" r="28.0095" transform="rotate(90 30 30)" fill="#D9D9D9" />

                    <g width="16" height="27" viewBox="0 0 16 27" fill="none" transform="translate(22, 18)">
                        <path d="M9 13L9 12L7 12L7 13L9 13ZM7.29289 26.7071C7.68342 27.0976 8.31658 27.0976 8.70711 26.7071L15.0711 20.3431C15.4616 19.9526 15.4616 19.3195 15.0711 18.9289C14.6805 18.5384 14.0474 18.5384 13.6569 18.9289L8 24.5858L2.34315 18.9289C1.95262 18.5384 1.31946 18.5384 0.928932 18.9289C0.538407 19.3195 0.538407 19.9526 0.928932 20.3431L7.29289 26.7071ZM7 13L7 26L9 26L9 13L7 13Z" fill="white" />
                        <line x1="8" y1="10" x2="8" y2="6" stroke="white" strokeWidth="2" />
                        <line x1="8" y1="4" x2="8" stroke="white" strokeWidth="2" />
                    </g>
                </svg>
            </div>

        </Page >
    )
};

export default LandingPage;