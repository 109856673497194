import React, { useState } from 'react';
import styled from 'styled-components';

const CheapContainer = styled.div`
  display: inline-block;
  background-color: white;
  margin: 6px;
  padding: 5px;
  border-radius: 100px;
  border: 1px solid #009BF3;
  color: #009BF3;
  cursor: pointer;
  font-size: 11px;  
`;

const ImavenCheap = ({ cheapList, onSelectCheap }) => {
    const handleCheapClick = (cheap) => {
        onSelectCheap(cheap);
    };

    return (
        <div style={{margin:"0 1rem"}}>
            {cheapList.map((cheap, index) => (
                <CheapContainer key={index} onClick={() => handleCheapClick(cheap)}>
                    <span style={{fontFamily:"Qanelas Soft",fontSize:"11px",lineHeight:"18px"}}>{cheap}...</span>
                    
                </CheapContainer>
            ))}
        </div>
    );
};

export default ImavenCheap;
