import React from 'react';
import './Popup.css';

const Popup = ({ isOpen, onClose, content }) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <div className={`popup ${isOpen ? 'open' : 'close'}`}>
      <div className="popup-overlay" onClick={handleClose} />
      <div className="popup-content">
        <button className="popup-close" onClick={handleClose}>
          &times;
        </button>
        <div className="popup-body">{content} </div>
      </div>
    </div>
  );
};

export default Popup;