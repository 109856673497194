import React, { useState, useEffect } from 'react';

import ScaleOne from '../../assets/question icons/scale/1.jpg';
import ScaleTwo from '../../assets/question icons/scale/2.jpg';
import ScaleThree from '../../assets/question icons/scale/3.jpg';
import ScaleFour from '../../assets/question icons/scale/4.jpg';
import ScaleFive from '../../assets/question icons/scale/5.jpg';

import TextOne from '../../assets/question icons/text/1.jpg';
import TextTwo from '../../assets/question icons/text/2.jpg';
import TextThree from '../../assets/question icons/text/3.jpg';
import TextFour from '../../assets/question icons/text/4.jpg';
import TextFive from '../../assets/question icons/text/5.jpg';

import YesNoOne from '../../assets/question icons/yes no/1.jpg';
import YesNoTwo from '../../assets/question icons/yes no/2.jpg';
import YesNoThree from '../../assets/question icons/yes no/3.jpg';
import YesNoFour from '../../assets/question icons/yes no/4.jpg';
import YesNoFive from '../../assets/question icons/yes no/5.jpg';

import RangeOne from '../../assets/question icons/range/1.jpg';
import RangeTwo from '../../assets/question icons/range/2.jpg';
import RangeThree from '../../assets/question icons/range/3.jpg';
import RangeFour from '../../assets/question icons/range/4.jpg';
import RangeFive from '../../assets/question icons/range/5.jpg';

import NumericOne from '../../assets/question icons/numeric/1.jpg';
import NumericTwo from '../../assets/question icons/numeric/2.jpg';
import NumericThree from '../../assets/question icons/numeric/3.jpg';
import NumericFour from '../../assets/question icons/numeric/4.jpg';
import NumericFive from '../../assets/question icons/numeric/5.jpg';

import RadioOne from '../../assets/question icons/radio/1.jpg';
import RadioTwo from '../../assets/question icons/radio/2.jpg';
import RadioThree from '../../assets/question icons/radio/3.jpg';
import RadioFour from '../../assets/question icons/radio/4.jpg';
import RadioFive from '../../assets/question icons/radio/5.jpg';

import CheckBoxOne from '../../assets/question icons/checkbox/1.jpg';
import CheckBoxTwo from '../../assets/question icons/checkbox/2.jpg';
import CheckBoxThree from '../../assets/question icons/checkbox/3.jpg';
import CheckBoxFour from '../../assets/question icons/checkbox/4.jpg';
import CheckBoxFive from '../../assets/question icons/checkbox/5.jpg';

import AskOne from '../../assets/ask/1.jpg';
import AskTwo from '../../assets/ask/2.jpg';
import AskThree from '../../assets/ask/3.jpg';
import AskFour from '../../assets/ask/4.jpg';
import AskFive from '../../assets/ask/5.jpg';

import RecommendationOne from '../../assets/recommendation/1.jpg';
import RecommendationTwo from '../../assets/recommendation/2.jpg';
import RecommendationThree from '../../assets/recommendation/3.jpg';
import RecommendationFour from '../../assets/recommendation/4.jpg';
import RecommendationFive from '../../assets/recommendation/5.jpg';

function RandomImage({ questionType }) {
    const imageSets = {
        'checkbox': [
            CheckBoxOne,
            CheckBoxTwo,
            CheckBoxThree,
            CheckBoxFour,
            CheckBoxFive,
        ],
        'numeric': [
            NumericOne,
            NumericTwo,
            NumericThree,
            NumericFour,
            NumericFive,
        ],
        'radio': [
            RadioOne,
            RadioTwo,
            RadioThree,
            RadioFour,
            RadioFive,
        ],
        'range': [
            RangeOne,
            RangeTwo,
            RangeThree,
            RangeFour,
            RangeFive,
        ],
        'scale': [
            ScaleOne,
            ScaleTwo,
            ScaleThree,
            ScaleFour,
            ScaleFive,
        ],
        'text': [
            TextOne,
            TextTwo,
            TextThree,
            TextFour,
            TextFive,
        ],
        'yesno': [
            YesNoOne,
            YesNoTwo,
            YesNoThree,
            YesNoFour,
            YesNoFive,
        ],
        'ask': [
            AskOne,
            AskTwo,
            AskThree,
            AskFour,
            AskFive
        ],
        'recommendation':[
            RecommendationOne,
            RecommendationTwo,
            RecommendationThree,
            RecommendationFour,
            RecommendationFive,
        ],
    };

    const [selectedImage, setSelectedImage] = useState('')
    useEffect(() => {
        if (questionType in imageSets) {
            const t = imageSets[questionType][Math.floor(Math.random() * imageSets[questionType].length)];
            console.log(t)
            setSelectedImage(t);
        }
    }, [questionType]);

    const divStyle = {
        backgroundImage: `url(${selectedImage})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: '200px'
    };

    return (
        <div style={divStyle}></div>
    );
}

export default RandomImage;

