import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import MessageBackground from '../../assets/background-message.svg';
import ImavenIcon from '../../assets/icon';
import ImavenTxt from '../../assets/Imaven.svg';
import HowDidWeDo from '../../assets/feedback/how-did-we-do.svg';
import OneThroughTen from '../Helpers/one-through-ten';
import OverallExp from '../../assets/feedback/overallEXP.svg'
import ProdRecommendation from '../../assets/feedback/prod-recommendation.svg'
import Recommendation from '../../assets/feedback/recommendation.svg'
import ImavenRadio from '../Helpers/imaven-radio';
import ImavenTextarea from '../Helpers/imaven-textarea';
import useAuthenticatedAxios from '../../interceptor/AuthenticatedAxios';
import toast from 'react-hot-toast';
import ImavenLoading from '../Helpers/imaven-loading-icon';
import CompleteImage from '../../assets/recommendation/complete.svg'
import DownArrowImg from '../../assets/recommendation/down-arrow.svg'
import NotAllowedImg from '../../assets/recommendation/now-allowed.svg'

const Container = styled.div`
    padding: 10px;
`;

const Top = styled.div`
    border-radius: 5px;
    // background: url(${MessageBackground});
    // height: 400px;    
    // width:100%;
    padding:0 2vw;
    background:linear-gradient(78deg, rgb(1, 189, 251) 0.81%, rgb(40, 160, 248) 88.23%);
    // background-size: contain;
    // background-position: center;
    // background-repeat: no-repeat;
    display:flex;
    justify-content: space-evenly;        
    align-items:center;
    flex-direction:column
`;

const IconWrapper = styled.div`
    width:50px;
    height: 54px;
    margin-top: 1vh;
    margin-buttom:1vh;
`;

const Title = styled.div`
    background: url(${ImavenTxt});  
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width:100%;
    height:100%;
`;

const TitleWrapper = styled.div`
    width: 119px;
    height: 22px;   
    margin-top:2vh;
`;

const CelebrationImg = styled.div`
    background: url(${CompleteImage});
    width: 225px;
    height: 244px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
`;

const TitleTxt = styled.span`
    color: #FFF;
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: Qanelas Soft;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 150% */
    letter-spacing: 0.6px;
    width: 251px;
`;

const DearUserWrapper = styled.div`
    padding: 5px;
    margin-top:30px;
`;

const DearUser = styled.span`
    color: #000;
    font-family: Qanelas Soft;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 175% */
    letter-spacing: 0.48px;
`;

const MessageForUser = styled.span`
    color: #333;
    font-family: Qanelas Soft;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 175% */
    letter-spacing: 0.48px;
    margin-top:11px;
`;

const MakeTextBlue = styled.span`
    color: #009BF3;
    font-family: Qanelas Soft;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.48px;

    &::before { content: " "; }
    &::after { content: " "; }
`

const QuestionContiner = styled.div`
    border-radius: 5px;
    background: #F5FAFD;
    height: 536px;
    display:flex;
    justify-content: space-evenly;        
    //align-items:center;
    flex-direction:column;
    padding:10px;
    margin-bottom: 30px;
`;

const QuestionTitle = styled.span`
    color: #009BF3;
    text-align: center;
    font-family: Qanelas Soft;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.6px;
`;

const QuestionImg = styled.div`
    ${props => `
    background: url(${props.imgUrl});
    width: 100%;
    height: 112px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
`}`

const QuestionPreTxt = styled.span`
    color: #333;
    font-family: Qanelas Soft;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.42px;
`;

const QuestionTxt = styled.div`
    color: #333;
    font-family: Qanelas Soft;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.42px;
`;

const BigQuestionTxt = styled.span`
    color: #333;
    text-align: center;
    font-family: Qanelas Soft;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 175% */
    letter-spacing: 0.48px;
`

const Legends = styled.div`
    display:flex;
    flex-direction:column;
    justify-content: space-between;
    height: 6vh;

`;

const Legend = styled.span`
    color: #999898;
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.33px;
`;

const TextAreaContainer = styled.div`
    width: 94%;
    height: 160px;
`;

const BtnContiner = styled.div`
    padding:10px;
`;

const SendBtn = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.3rem 1rem 0.3rem 1rem;
    background-color: #009BF3;;
    color: #FFFFFF;
    border: none;
    border-radius: 30px;
    font-size: 0.8rem;
    height:45px;
    box-shadow: 1px 1px 8px rgba(86, 204, 242, 0.1);
    width: 100%; // 100% of the parent width
    float:right;
    transition: background-color 0.2s ease-in-out;
`;

const FinalMessage = styled.span`
    color: #333;
    text-align: center;
    font-family: Qanelas Soft;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 175% */
    letter-spacing: 0.48px;

    display:flex;
    padding-right:46px;
    padding-left:46px;
    margin-buttom:5vh
`;

const LoadingScreen = styled.div`
    position:absolute;
    z-index:1000;    
    height:100%;
`;

const TopPage = styled.div`
    background: linear-gradient(180deg, #01BDFB 0%, #0382F6 100%);
    height:10vh;
    width:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction: column;
`;

const TitleSpan = styled.span`
    color: #FFF;
    text-align: center;
    font-family: Varela Round;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
`;

const BugBlueText = styled.span`
    color: #009BF3;
    text-align: center;
    font-family: Qanelas Soft;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 93.75% */
    letter-spacing: 0.96px;
    text-transform: uppercase;
`;

const ErrorText = styled.span`
    color: #333;
    text-align: center;
    font-family: Qanelas Soft;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 150% */
    letter-spacing: 0.6px;
`;

const BackToHomePage = styled.span`
    color: #333;
    text-align: center;
    font-family: Qanelas Soft;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 187.5% */
    letter-spacing: 0.48px;
`;

const DownArrow = styled.div`
    background: url(${DownArrowImg});
    width: 20px;
    height: 20px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-top:2vh;
`;

const OopsImg = styled.div`
    background: url(${NotAllowedImg});
    width: 225px;
    height: 244px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
`;

const NextBtn = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.3rem 1rem 0.3rem 1rem;
    background-color: #499EDC;
    color: #FFFFFF;
    border: none;
    border-radius: 30px;
    font-size: 0.8rem;
    height:45px;
    box-shadow: 1px 1px 8px rgba(86, 204, 242, 0.1);
    width: 100%; // 100% of the parent width
    float:right;
    transition: background-color 0.2s ease-in-out;
`;


const NotAllowedPage = ({ handleClick }) => (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <TopPage>
            <TitleSpan>IMAVEN</TitleSpan>
        </TopPage>
        <div style={{ marginTop: '5vh' }}>
            <BugBlueText>OOPS!</BugBlueText>
        </div>
        <div style={{ marginTop: '5vh', width: "65vw", textAlign: 'center' }}>
            <ErrorText>You are not allowed to leave Feedback.</ErrorText>
        </div>
        <OopsImg />
        <div>
            <BackToHomePage>Back to Home page</BackToHomePage>
        </div>
        <DownArrow />
        <div style={{ width: '80%', marginTop: '5vh' }}>
            <NextBtn onClick={handleClick}>Back</NextBtn>
        </div>
    </div>
);

const FeedbackCompelte = ({ handleClick }) => (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <TopPage>
            <TitleSpan>IMAVEN</TitleSpan>
        </TopPage>
        <div style={{ marginTop: '5vh' }}>
            <BugBlueText>Thank you!</BugBlueText>
        </div>
        <div style={{ marginTop: '5vh', width: "65vw", textAlign: 'center' }}>
            <ErrorText>You have already left your Feedback.</ErrorText>
        </div>
        <CelebrationImg />
        <div>
            <BackToHomePage>Back to Home page</BackToHomePage>
        </div>
        <DownArrow />
        <div style={{ width: '80%', marginTop: '5vh' }}>
            <NextBtn onClick={handleClick}>Back</NextBtn>
        </div>
    </div>
);

const Feedback = () => {
    const authenticatedAxios = useAuthenticatedAxios();
    const navigate = useNavigate();
    const { convoId } = useParams();

    const [loading, setLoading] = useState(true);
    const [firstQuestion, setFirstQuesion] = useState(10);
    const [secondQuestion, setSecondQuestion] = useState("0");
    const [thirdQuestion, setThirdQuestion] = useState("0");
    const [fourthQuestion, setFourthQuestion] = useState("0");
    const [comment, setCommnet] = useState("");
    const [conversationId, setConversationId] = useState("");

    const [productName, setProductName] = useState("");
    const [productType, setProductType] = useState("");
    const [userName, setUserName] = useState("");

    const [asError, setAsError] = useState("");

    const secondQOp = [{ value: "0", label: "Yes, it was spot on!" },
    { value: "1", label: "Mostly, with a few misses. (Tell us what we missed in the comments section)" },
    { value: "2", label: "No, it didn’t match my requirements." }];

    const thirdQOp = [{ value: "0", label: "Yes. On the spot." },
    { value: "1", label: "Yes, but it took some more time." },
    { value: "2", label: "No, but I intend to buy it in the future." }, { value: "3", label: "No." }];

    const fourthQOp = [{ value: "0", label: "Definitely!" },
    { value: "1", label: "Probably" }, { value: "2", label: "Not sure" },
    { value: "3", label: "Probably not" }, { value: "4", label: "Definitely not" }];

    useEffect(() => {
        // const params = new URLSearchParams(location.search);
        // const id = params.get('id');
        const queryParams = { conversationId: convoId };

        setConversationId(convoId);

        const getData = async (queryParams) => {
            try {
                const response = await authenticatedAxios.get('/feedbackData', { params: queryParams });
                if (response.status >= 200 && response.status < 300) {
                    if (response.data.isAnswerd) {
                        //TODO already answerd screen
                        toast.success("Feedback already given");
                        setAsError("given");
                        setLoading(false);
                    } else {
                        setProductName(response.data.response.productName);
                        setProductType(response.data.response.productType);
                        setUserName(response.data.response.userName);
                        setLoading(false);
                        setAsError("");
                    }
                } else {
                    toast.error(response.data || "An error occurred");
                    setLoading(false);
                    setAsError("notAllowed");
                }
            } catch (error) {
                toast.error(error.message || "An error occurred");
                setLoading(false);
                setAsError("notAllowed");
            }
        }

        getData(queryParams);
    }, [authenticatedAxios, convoId]);

    const handleNumberSelect = (number) => {
        setFirstQuesion(number);
    };

    const handleSecondOptionChange = (value) => {
        setSecondQuestion(value);
    }

    const handleThirdOptionChange = (value) => {
        setThirdQuestion(value);
    }

    const handleFourthOptionChange = (value) => {
        setFourthQuestion(value);
    }

    const handleTextChange = (val) => {
        setCommnet(val)
    }

    const saveFeedback = async () => {
        setLoading(true);
        try {
            const response = await authenticatedAxios.post('/savefeedback', {
                conversationId: conversationId,
                howDidWeDo: firstQuestion,
                overallExp: secondQuestion,
                productRecommendation: thirdQuestion,
                recommendations: fourthQuestion,
                comment: comment
            });
            if (response.status >= 200 && response.status < 300) {
                setAsError("given");
                setLoading(false);
            }
            else {
                toast.error(response.data || "An error occurred");
                setLoading(false);
            }
        } catch (error) {
            toast.error(error.message || "An error occurred");
            setLoading(false);
        }
    }

    const GoToMain = () => {
        navigate('/', { replace: true })
    }

    const renderErrorPage = () => {
        if (asError === "notAllowed")
            return <NotAllowedPage handleClick={GoToMain} />

        return <FeedbackCompelte handleClick={GoToMain} />
    }

    return (
        <div>
            {loading ?
                (<LoadingScreen >
                    <ImavenLoading type={"first"} />
                </LoadingScreen>) :
                (asError !== "") ? renderErrorPage() : <Container>
                    <Top>
                        <IconWrapper>
                            <ImavenIcon animate={false}></ImavenIcon>
                        </IconWrapper>
                        <TitleWrapper>
                            <Title />
                        </TitleWrapper>
                        <CelebrationImg />
                        <TitleTxt>
                            We'd Love Your Feedback on IMavenAI!
                        </TitleTxt>
                    </Top>

                    <DearUserWrapper>
                        <DearUser>Dear {userName},</DearUser>
                        <br />
                        <br />
                        <MessageForUser>I hope this message finds you well. Thank you for using
                            <MakeTextBlue>IMavenAI</MakeTextBlue>
                            on your recent search to find the perfect
                            <MakeTextBlue>{productType}</MakeTextBlue>
                            for you. We're always striving to improve, and would love to hear about your experience using
                            <MakeTextBlue>IMavenAI.</MakeTextBlue></MessageForUser>
                    </DearUserWrapper>

                    <br />
                    <br />

                    <QuestionContiner>
                        <QuestionTitle>So, how did we do?</QuestionTitle>
                        <QuestionImg imgUrl={HowDidWeDo}></QuestionImg>
                        <QuestionPreTxt>Would you mind sharing your experience with our
                            application with us? With just a few quick answers to the
                            questions below you will help us deliver an even better
                            service to you and others in the future. <b>Here we go:</b>
                        </QuestionPreTxt>
                        <QuestionTxt>On a scale from 1-10, how would you rate your overall
                            experience with <MakeTextBlue>IMavenAI</MakeTextBlue>?
                        </QuestionTxt>
                        <OneThroughTen onSelect={handleNumberSelect}></OneThroughTen>
                        <Legends>
                            <Legend>1 - (Not Satisfied)</Legend>
                            <Legend>5 - (Neutral)</Legend>
                            <Legend>10 - (Extremely Satisfied)</Legend>
                        </Legends>
                    </QuestionContiner>

                    <QuestionContiner>
                        <QuestionTitle>Overall Experience:</QuestionTitle>
                        <QuestionImg imgUrl={OverallExp}></QuestionImg>
                        <BigQuestionTxt>
                            Was the product recommended to you <MakeTextBlue>({productName})</MakeTextBlue> the right product for your needs?
                        </BigQuestionTxt>
                        <ImavenRadio options={secondQOp}
                            onChange={handleSecondOptionChange}
                            selectedOption={secondQuestion}>
                        </ImavenRadio>
                    </QuestionContiner>

                    <QuestionContiner>
                        <QuestionTitle>Product Recommendations:</QuestionTitle>
                        <QuestionImg imgUrl={ProdRecommendation}></QuestionImg>
                        <BigQuestionTxt>
                            Did you buy <MakeTextBlue>{productName}</MakeTextBlue>
                            following <MakeTextBlue>IMavenAI’s</MakeTextBlue> recommendation?
                        </BigQuestionTxt>
                        <ImavenRadio options={thirdQOp}
                            onChange={handleThirdOptionChange}
                            selectedOption={thirdQuestion}>
                        </ImavenRadio>
                    </QuestionContiner>

                    <QuestionContiner>
                        <QuestionTitle>Recommendations:</QuestionTitle>
                        <QuestionImg imgUrl={Recommendation}></QuestionImg>
                        <BigQuestionTxt>
                            Would you recommend <MakeTextBlue>IMavenAI</MakeTextBlue> to a friend or family member?
                        </BigQuestionTxt>
                        <ImavenRadio options={fourthQOp}
                            onChange={handleFourthOptionChange}
                            selectedOption={fourthQuestion}>
                        </ImavenRadio>
                    </QuestionContiner>

                    <QuestionContiner>
                        <QuestionTitle>Additional Comments:</QuestionTitle>
                        <BigQuestionTxt>
                            We'd love to hear your thoughts on <MakeTextBlue>IMavenAI’s</MakeTextBlue> recommendation of <MakeTextBlue>{productName}</MakeTextBlue>
                            as well as any other feedback you might have about the overall experience.
                        </BigQuestionTxt>
                        <TextAreaContainer>
                            <ImavenTextarea placeholder="Comments..." onChange={handleTextChange}></ImavenTextarea>
                        </TextAreaContainer>
                        <BtnContiner>
                            <SendBtn onClick={saveFeedback}>Send</SendBtn>
                        </BtnContiner>
                    </QuestionContiner>

                    <div style={{ marginBottom: "5vh" }}>
                        <FinalMessage>
                            Thank you for your time and for being a part of our journey.
                            Your insights are key to helping us serve you better!
                        </FinalMessage>
                    </div>

                </Container>}
        </div>
    );
}

export default Feedback