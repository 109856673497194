import React, { useState } from 'react';

function ImavenBreadcrumb({ number, color }) {
    return (<div>
        <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 100 100" fill="none">
            <path d="M26.2427 50.001C12.2441 50.001 0.863281 38.7859 0.863281 25.0006C0.863281 11.2152 12.2485 0.00012486 26.2427 0.00012486C30.6162 -0.00191089 34.916 1.11028 38.7243 3.22869C42.5326 5.34709 45.7202 8.39971 47.9773 12.0901C48.1479 12.3948 48.1921 12.7528 48.1008 13.0889C48.0095 13.4251 47.7896 13.7134 47.4873 13.8934C47.185 14.0735 46.8237 14.1313 46.4791 14.0548C46.1345 13.9782 45.8333 13.7733 45.6387 13.4829C41.4968 6.7273 34.2464 2.69358 26.2445 2.69358C13.7577 2.69358 3.59913 12.7003 3.59913 25.0006C3.59913 37.3008 13.7577 47.3075 26.2445 47.3075C38.7312 47.3075 48.8881 37.3008 48.8881 25.0006C48.8971 24.6493 49.0451 24.3154 49.3005 24.0701C49.5559 23.8248 49.8984 23.6875 50.2551 23.6875C50.6118 23.6875 50.9544 23.8248 51.2098 24.0701C51.4652 24.3154 51.6132 24.6493 51.6222 25.0006C51.6204 38.7859 40.2361 50.001 26.2427 50.001Z" fill={color} />
            <path d="M21.0049 3.34769H2.25379C1.89722 3.33881 1.55828 3.19302 1.30927 2.94142C1.06026 2.68983 0.920898 2.35236 0.920898 2.00097C0.920898 1.64958 1.06026 1.31209 1.30927 1.0605C1.55828 0.808907 1.89722 0.663127 2.25379 0.65424H21.0049C21.1874 0.649693 21.3689 0.68116 21.5388 0.746796C21.7086 0.812431 21.8635 0.9109 21.9942 1.03641C22.1248 1.16191 22.2287 1.31191 22.2996 1.47756C22.3705 1.64322 22.407 1.82119 22.407 2.00097C22.407 2.18075 22.3705 2.3587 22.2996 2.52436C22.2287 2.69002 22.1248 2.84001 21.9942 2.96552C21.8635 3.09102 21.7086 3.18949 21.5388 3.25513C21.3689 3.32076 21.1874 3.35224 21.0049 3.34769Z" fill={color}  transform="translate(48 22)" />
            <path d="M1.4843 17.7876C1.21425 17.787 0.950466 17.7075 0.726237 17.5592C0.502008 17.411 0.327389 17.2006 0.224423 16.9547C0.121457 16.7087 0.0947643 16.4383 0.147708 16.1774C0.200651 15.9165 0.330857 15.677 0.521895 15.4889L7.07499 9.03326L0.451908 2.51122C0.195619 2.25863 0.0516758 1.91608 0.0517578 1.55894C0.0518399 1.2018 0.19593 0.859322 0.452336 0.606843C0.708741 0.354364 1.05646 0.212566 1.41899 0.212647C1.78152 0.212727 2.12917 0.354676 2.38546 0.607269L9.97096 8.07999C10.098 8.20502 10.1987 8.35349 10.2675 8.51691C10.3363 8.68033 10.3717 8.8555 10.3717 9.0324C10.3717 9.2093 10.3363 9.38446 10.2675 9.54788C10.1987 9.7113 10.098 9.85977 9.97096 9.9848L2.4467 17.3972C2.19101 17.6477 1.84491 17.7881 1.4843 17.7876Z" fill={color}  transform="translate(63 15.5)" />
            <text x="25%" y="28%" textAnchor="middle" dominantBaseline="middle" fill={color} fontSize="26">
                {number}
            </text>
        </svg>
    </div>);
}

export default ImavenBreadcrumb;