import React, { useState, useEffect } from 'react';
import ImavenBreadcrumb from './imaven-breadcrumb';

function ImavenBreadcrumbs({ numbers, initialIndex, disablePrev, disableNext }) {

    const [currentIndex, setCurrentIndex] = useState(initialIndex);

    useEffect(() => {
        setCurrentIndex(initialIndex);
    }, [initialIndex]);


    const handlePrev = () => {
        if (!disablePrev) {
            setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
        }
    };

    const handleNext = () => {
        if (!disableNext) {
            setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, numbers.length - 1));
        }
    };

    const handleClick = (index) => {
        setCurrentIndex(index);
    };

    // const handlePrev = () => {
    //     if (!disablePrev) {
    //         setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    //     }
    // };

    // const handleNext = () => {
    //     if (!disableNext) {
    //         setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, numbers.length - 1));
    //     }
    // };

    // const handleClick = (index) => {
    //     if (currentIndex === 0 && index === 0) {
    //         // If the current index is 0 (number 1), and the clicked index is 0 (number 1),
    //         // do nothing and keep showing only the selected number
    //         return;
    //     }
    //     setCurrentIndex(currentIndex + index - 1);
    // };

    if (currentIndex === null) {
        // Return null if currentIndex is null to hide the list
        return null;
    }

    return (
        <div style={{ display: 'flex', alignItems: 'center', height: '10vh',justifyContent:'center' }}>
            <div onClick={handlePrev}
                disabled={currentIndex === 0 || disablePrev}
                style={{ cursor: 'pointer', marginRight: '10px',marginTop:"20px" }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="20" viewBox="0 0 17 29" fill="none">
                    <path d="M14.64 4.02101e-06C15.0855 -0.000278306 15.521 0.12986 15.8915 0.37394C16.262 0.618021 16.5508 0.965071 16.7214 1.37117C16.8919 1.77727 16.9364 2.22416 16.8495 2.65527C16.7625 3.08638 16.5478 3.48235 16.2327 3.79303L5.437 14.4453L16.3408 25.2072C16.5498 25.4136 16.7157 25.6586 16.8288 25.9282C16.9419 26.1978 17.0001 26.4867 17 26.7785C16.9999 27.0703 16.9416 27.3592 16.8284 27.6288C16.7152 27.8984 16.5492 28.1433 16.3401 28.3495C16.1309 28.5558 15.8826 28.7194 15.6094 28.8311C15.3362 28.9427 15.0433 29.0001 14.7476 29C14.4519 28.9999 14.1591 28.9424 13.8859 28.8307C13.6127 28.7189 13.3645 28.5552 13.1554 28.3488L0.65895 16.0183C0.237 15.6013 -1.22077e-06 15.0361 -1.27228e-06 14.4468C-1.3238e-06 13.8575 0.237 13.2923 0.658949 12.8752L13.0545 0.644266C13.4758 0.23091 14.0459 -0.00077794 14.64 4.02101e-06Z" fill="#DFDFDF" />
                </svg>
            </div>
            {numbers.slice(currentIndex === 0 ? 0 : currentIndex - 1, currentIndex + 2).map((number, index) => (
                <div
                    key={index}
                    style={{ cursor: 'pointer', marginRight: '5px', marginTop: '60px' }}
                // onClick={() => handleClick(index + (currentIndex === 0 ? 0 : currentIndex - 1))}
                >
                    <ImavenBreadcrumb
                        number={number}
                        color={index === 1 ? '#855CA6' : '#cccccc'} />
                </div>
            ))}
            <div onClick={handleNext}
                disabled={currentIndex === numbers.length - 1 || disableNext}
                style={{ cursor: 'pointer', marginRight: '10px',marginTop:"20px" }}>
                <svg xmlns="http://www.w3.org/2000/svg" x="50%" width="15" height="20" viewBox="0 0 17 29" fill="none">
                    <path d="M2.35999 29C1.9145 29.0003 1.47896 28.8701 1.10846 28.6261C0.737954 28.382 0.449148 28.0349 0.278627 27.6288C0.108107 27.2227 0.0635498 26.7758 0.150539 26.3447C0.237528 25.9136 0.452182 25.5177 0.767334 25.207L11.563 14.5547L0.659233 3.79281C0.450173 3.58644 0.284337 3.34145 0.17123 3.07184C0.058124 2.80223 -6.68564e-05 2.51328 5.7643e-08 2.22149C6.69717e-05 1.92969 0.0583874 1.64076 0.171617 1.3712C0.284847 1.10164 0.450782 0.856737 0.659937 0.650453C0.869091 0.444169 1.11737 0.280552 1.3906 0.168948C1.66384 0.0573435 1.9567 -6.59817e-05 2.25242 5.69087e-08C2.54814 6.60955e-05 2.84094 0.0576034 3.11413 0.16933C3.38732 0.281056 3.63553 0.444779 3.84459 0.651156L16.341 12.9817C16.763 13.3987 17 13.9639 17 14.5532C17 15.1425 16.763 15.7077 16.341 16.1248L3.94547 28.3557C3.52424 28.7691 2.95408 29.0008 2.35999 29Z" fill="#DFDFDF" />
                </svg>
            </div>
        </div>
    );
}

export default ImavenBreadcrumbs;