import styled from 'styled-components';
import React, { useState } from 'react';

const Card = styled.div`
  border-radius: 3px;
  background: ${props => props.isSelected ? '#009BF3' : '#EEE'};
  color: ${props => props.isSelected ? '#FFF' : '#999898'};
  width: 22px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
//   margin: 8px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  font-family: Qanelas Soft;
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const OneThroughTen = ({ onSelect }) => {
    const [selectedNumber, setSelectedNumber] = useState(10);

    const handleSelect = number => {
        setSelectedNumber(number);
        onSelect(number); // Call the provided callback function
    };

    return (
        <Container>
            {Array.from({ length: 10 }, (_, i) => i + 1).map(number => (
                <Card
                    key={number}
                    isSelected={selectedNumber === number}
                    onClick={() => handleSelect(number)}>
                    {number}
                </Card>
            ))}
        </Container>
    );
}

export default OneThroughTen;