import React, { createContext, useState } from 'react';
import Popup from './Popup';

export const PopupContext = createContext();

const PopupProvider = ({ children }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupContent, setPopupContent] = useState('');

  const openPopup = (content) => {
    setPopupContent(content);
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <PopupContext.Provider value={{ openPopup, closePopup }}>
      {children}
      <Popup isOpen={isPopupOpen} onClose={closePopup} content={popupContent} />
    </PopupContext.Provider>
  );
};

export default PopupProvider;
