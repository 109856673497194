// import React, { useState } from 'react';
// import styled from 'styled-components';

// const CheckboxContainer = styled.div`
//   display: flex;
//   flex-direction: column;
// `;

// const Label = styled.label`
//   display: flex;
//   align-items: center;
//   margin-bottom: 8px;
//   cursor: pointer;
// `;

// const Checkmark = styled.div`
//   width: 20px;
//   height: 20px;
//   border: 2px solid ${({ checked }) => (checked ? '#499EDC' : '#ccc')};
//   border-radius: 4px;
//   margin-right: 8px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   cursor: pointer;
//   background-color: #fff;
//   transition: all 0.2s;

//   &::before {
//     content: '✔';
//     display: ${({ checked }) => (checked ? 'block' : 'none')};
//     color: ${({ checked }) => (checked ? '#499EDC' : '#fff')};
//   }
// `;

// const CheckboxInput = styled.input`
//   opacity: 0;
//   position: absolute;

//   &:checked + ${Checkmark} {
//     border: 2px solid #499EDC;
//     background-color: #fff;
//   }

//   &:disabled + ${Checkmark} {
//     opacity: 0.5;
//     cursor: not-allowed;
//   }
// `;

// const ImavenCheckbox = ({ options, maxSelected, onChange }) => {
//   const [selectedOptions, setSelectedOptions] = useState([]);

//   const handleCheckboxChange = (option) => {
//     let updatedSelection;

//     if (selectedOptions.includes(option)) {
//       updatedSelection = selectedOptions.filter((item) => item !== option);
//     } else if (!maxSelected || selectedOptions.length < maxSelected) {
//       updatedSelection = [...selectedOptions, option];
//     } else {
//       return; // Max selected reached, do not update
//     }

//     setSelectedOptions(updatedSelection);
//     onChange(updatedSelection);
//   };

//   const isDisabled = (option) => {
//     if (maxSelected === undefined) return false;
//     return selectedOptions.length >= maxSelected && !selectedOptions.includes(option);
//   };

//   return (
//     <CheckboxContainer>
//       {options.map((option) => {
//         const checked = selectedOptions.includes(option);
//         const disabled = isDisabled(option);

//         return (
//           <Label key={option}>
//             <CheckboxInput
//               type="checkbox"
//               checked={checked}
//               disabled={disabled}
//               onChange={() => handleCheckboxChange(option)}
//             />
//             <Checkmark checked={checked} />
//             {option}
//           </Label>
//         );
//       })}
//     </CheckboxContainer>
//   );
// };

// export default ImavenCheckbox;
import React, { useState } from 'react';
import styled from 'styled-components';

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  cursor: pointer;
`;

const Checkmark = styled.div`
  width: 25px;
  height: 25px;
  border: 2px solid ${({ checked }) => (checked ? '#499EDC' : '#ccc')};
  border-radius: 4px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #fff;
  transition: all 0.2s;

  &::before {
    content: '✔';
    display: ${({ checked }) => (checked ? 'block' : 'none')};
    color: ${({ checked }) => (checked ? '#499EDC' : '#fff')};
  }
`;

const CheckboxInput = styled.input`
  opacity: 0;
  position: absolute;
  width: 9vw;

  &:checked + ${Checkmark} {
    border: 2px solid #499EDC;
    background-color: #fff;
  }

  &:disabled + ${Checkmark} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TextArea = styled.textarea`
  width: 300px; // adjust to your desired width
  height: 150px; // adjust to your desired height
  fill: #FFF;
  background-color: #FFF;
  filter: drop-shadow(1px 1px 8px rgba(0, 0, 0, 0.10));
  border: none;
  resize: none; // to prevent resizing by user
  padding: 10px; // optional for inner spacing
  font-size: 16px; // optional

    &:focus {
    outline: none;
    -webkit-tap-highlight-color: transparent;
  }
`;

const ImavenCheckbox = ({ options, maxSelected, onChange }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [specificText, setSpecificText] = useState('');
  const [isSpecificSelected, setIsSpecificSelected] = useState(false);

  const handleCheckboxChange = (option) => {
    // Check if the option is 'other' or starts with 'other'
    const isSpecificOption = option.trim().toLowerCase() === 'other' || option.trim().toLowerCase().startsWith('other ');
  
    let updatedSelection;

    if (selectedOptions.includes(option)) {
      updatedSelection = selectedOptions.filter((item) => item !== option);
      if (isSpecificOption) {
        setIsSpecificSelected(false);
        setSpecificText('');
      }
    } else if (isSpecificOption) {
      updatedSelection = [option];
      setIsSpecificSelected(true);
    } else if (option.toLowerCase().includes('none') || option.toLowerCase().includes('specific')) {
      updatedSelection = [option]
    } else if (!maxSelected || selectedOptions.length < maxSelected) {
      const index = selectedOptions.findIndex((item) => item.toLowerCase().includes('none') || item.toLowerCase().includes('specific'))
      if(index != -1){
        selectedOptions.splice(index,1);
      }
      updatedSelection = [...selectedOptions, option];
    } else {
      return; // Max selected reached, do not update
    }



    setSelectedOptions(updatedSelection);
    onChange(updatedSelection);
  };

  const isDisabled = (option) => {
    const isSpecificOption = option.toLowerCase().includes('specific');
    if (isSpecificSelected && !isSpecificOption) return true;
    if (maxSelected === undefined) return false;
    return selectedOptions.length >= maxSelected && !selectedOptions.includes(option);
  };

  const handleTextArea = (value) => {
    setSpecificText(value)
    onChange([value]);

  }

  return (
    <CheckboxContainer>
      {options.map((option) => {
        const checked = selectedOptions.includes(option);
        const disabled = isDisabled(option);

        return (
          <Label key={option}>
            <div>
              <CheckboxInput
                type="checkbox"
                checked={checked}
                // disabled={disabled}
                onChange={() => handleCheckboxChange(option)}
              />
              <Checkmark checked={checked} />
            </div>
            <span>
              {option}
            </span>
          </Label>
        );
      })}
      {isSpecificSelected && (
        <TextArea
          rows="4"
          value={specificText}
          onChange={(e) => handleTextArea(e.target.value)}
          placeholder="Please specify..."
        />
      )}
    </CheckboxContainer>
  );
};

export default ImavenCheckbox;
