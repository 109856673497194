import React, { useState } from 'react';
import styled from 'styled-components';

const NumericInputWrapper = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  width: 100px;
  text-align: center;
  background: ${props => props.disabled ? '#f5f5f5' : 'white'};
`;

const ImavenNumeric = ({ value, onChange, disabled }) => {
    const handleInputChange = (event) => {
        const num = event.target.value;
        if (!num || /^[0-9]*$/.test(num)) {
            onChange(num);
        }
    };

    return (
        <NumericInputWrapper
            type="text"
            value={value}
            onChange={handleInputChange}
            disabled={disabled}
            placeholder="Enter number"
        />
    );
}

export default ImavenNumeric;