import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import Sidebar from '../Layout/Side-Menu';
import HamburgerButton from '../Helpers/hamburger-button';

const TopPage = styled.div`
    background: linear-gradient(180deg, #01BDFB 0%, #0382F6 100%);
    height:10vh;
    width:100%;
    display:flex;
    justify-content:space-around;
    align-items:center;
`;

const TitleSpan = styled.span`
    color: #FFF;
    text-align: center;
    font-family: Varela Round;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
`;

export const NavBar = styled.nav`
  display: flex;
  flex-wrap: wrap; /* Allow the items to wrap as needed */
  justify-content: center;
  margin-bottom: 20px;
  padding: 10px;

  @media (max-width: 768px) { /* Adjust for smaller screens */
    flex-direction: column;
    align-items: center;
  }
`;

export const NavButton = styled.button`
  margin: 5px; /* Add some space between buttons */
  padding: 10px 20px;
  background-color: #4a4c4f;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #4a4c4f;
  }

  @media (max-width: 768px) { /* Adjust button size for smaller screens */
    width: 80%; /* Make the buttons wider on small screens */
    margin-bottom: 10px;
  }
`;


export const Section = styled.section`
  margin: 20px 0;
`;

export const Heading = styled.h2`
  font-size: 1.5em;
  color: #333;
`;

export const Paragraph = styled.p`
  font-size: 1em;
  color: #666;
`;

const About = () => {
    const [isOpen, setIsOpen] = useState(false);

    const tradeMarkRef = useRef(null);
    const tosRef = useRef(null);
    const privacyPolicyRef = useRef(null);
    const copyrightProtectionRef = useRef(null);
    const disclaimersRef = useRef(null);
    const cookieRef = useRef(null);
    const accessibilityComplianceStatementRef = useRef(null);

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const scrollToRef = (ref) => {
        window.scrollTo(0, ref.current.offsetTop);
    };

    return (<div>
        {isOpen && <Sidebar close={toggleSidebar} />}

        <TopPage>
            <HamburgerButton isOpen={isOpen} toggle={toggleSidebar} ></HamburgerButton>
            <TitleSpan>IMAVEN </TitleSpan>
            <div> &nbsp;</div>
        </TopPage>

        <NavBar>
            <NavButton onClick={() => scrollToRef(tradeMarkRef)}>Trade Mark</NavButton>
            <NavButton onClick={() => scrollToRef(tosRef)}>Terms of Service</NavButton>
            <NavButton onClick={() => scrollToRef(privacyPolicyRef)}>Privacy Policy</NavButton>
            <NavButton onClick={() => scrollToRef(copyrightProtectionRef)}>Copyright Protection</NavButton>
            <NavButton onClick={() => scrollToRef(disclaimersRef)}>Disclaimers</NavButton>
            <NavButton onClick={() => scrollToRef(cookieRef)}>Cookies</NavButton>
            <NavButton onClick={() => scrollToRef(accessibilityComplianceStatementRef)}>Accessibility Compliance Statement</NavButton>
        </NavBar>

        <div style={{ padding: "22px" }}>

            <Section ref={tradeMarkRef}>
                <Heading>Trademark Protection for Imaven</Heading>
                <Paragraph>
                    <h2>1. Trademark Notice:</h2>
                    <ul>
                        <li>The Imaven name, logo, and any other product or service names, designs, slogans, and trademarks displayed on our Services are registered and unregistered trademarks of Imaven or its affiliates or licensors. They may not be used without the prior written permission of Imaven.</li>
                    </ul>

                    <h2>2. Protected Elements:</h2>
                    <ul>
                        <li>All trademarks, service marks, trade names, logos, domain names, and any other features of the Imaven brand ("Imaven Trademarks") are the sole property of Imaven or its licensors. This policy applies to both registered and unregistered trademarks.</li>
                    </ul>

                    <h2>3. Use of Trademarks:</h2>
                    <ul>
                        <li>The Imaven Trademarks may not be used in connection with any product or service that is not Imaven's, in any manner that is likely to cause confusion among customers, or in any manner that disparages or discredits Imaven.</li>
                    </ul>

                    <h2>4. Prohibited Uses:</h2>
                    <ul>
                        <li>You may not use Imaven Trademarks as part of a link to or from any site unless the link is approved in advance by Imaven in writing. Additionally, you may not use Imaven Trademarks in meta tags without Imaven’s explicit permission.</li>
                    </ul>

                    <h2>5. Infringement:</h2>
                    <ul>
                        <li>Any unauthorized use of the Imaven Trademarks is a violation of Imaven's trademark rights and applicable laws and could lead to legal action.</li>
                    </ul>

                    <h2>6. Reporting Unauthorized Use:</h2>
                    <ul>
                        <li>If you become aware of any use of the Imaven Trademarks that violates these terms or infringes upon trademark law, please report this to us at <a href="mailto:legal@imavenai.com">legal@imavenai.com</a>.</li>
                    </ul>

                    <h2>7. Trademark Claims:</h2>
                    <ul>
                        <li>Imaven respects the trademark rights of others. If you believe that your trademark has been infringed upon by our Services, please provide a notification of the infringement to <a href="mailto:legal@imavenai.com">legal@imavenai.com</a>.</li>
                    </ul>

                    <h3>Contact Information:</h3>
                    <ul>
                        <li>For questions or concerns regarding this Trademark Protection, please contact Imaven at <a href="mailto:legal@imavenai.com">legal@imavenai.com</a>.</li>
                    </ul>
                </Paragraph>
            </Section>

            <br />
            <br />
            
            <Section ref={tosRef}>
                <Heading>Terms of Service</Heading>
                <Paragraph>
                    <p><strong>Effective Date: January 1, 2024</strong></p>

                    <h2>1. Acceptance of Terms</h2>
                    <p>Welcome to Imaven. By accessing or using our website, services, or any applications (including mobile applications) made available by Imaven (together, the "Services"), you agree to be bound by these Terms of Service ("Terms").</p>

                    <h2>2. Changes to Terms</h2>
                    <p>Imaven reserves the right to modify or replace these Terms at any time. We will make reasonable efforts to notify you of any changes. Your continued use of the Services following any changes indicates your acceptance of the new Terms.</p>

                    <h2>3. Description of Services</h2>
                    <p>Imaven provides an online platform connecting users with advisors for product-related advice. The Services are intended for informational purposes only and should not replace professional advice.</p>

                    <h2>4. User Accounts</h2>
                    <p>To access certain features of the Services, you must create an account. You agree to provide accurate and complete information and keep this information up-to-date. You are responsible for safeguarding your account and should notify Imaven immediately of any breach of security or unauthorized use of your account.</p>

                    <h2>5. Use of Services</h2>
                    <p>Your use of the Services must comply with all applicable laws, rules, and regulations. Imaven reserves the right to terminate or suspend your access to the Services for any violation of these Terms.</p>

                    <h2>6. Intellectual Property Rights</h2>
                    <p>All content on the Services, including text, graphics, logos, and software, is the property of Imaven or its licensors and is protected by intellectual property laws.</p>

                    <h2>7. Disclaimers</h2>
                    <p>Imaven provides the Services on an "as is" and "as available" basis. We do not warrant the accuracy, completeness, or usefulness of the Services and disclaim all liability and responsibility arising from any reliance on such materials.</p>

                    <h2>8. Limitation of Liability</h2>
                    <p>Imaven, its affiliates, and its licensors will not be liable for any damages of any kind arising from the use of the Services or from any information, content, materials, or products included on or otherwise made available to you through the Services.</p>

                    <h2>9. Indemnification</h2>
                    <p>You agree to indemnify and hold harmless Imaven, its officers, directors, employees, and agents from and against any claims, actions, or demands, including, without limitation, reasonable legal and accounting fees, arising from your use of the Services or your breach of these Terms.</p>

                    <h2>10. Governing Law</h2>
                    <p>These Terms shall be governed by the laws of Israel, without regard to its conflict of law provisions.</p>

                    <h2>11. Contact Information</h2>
                    <p>For any questions about these Terms, please contact us at <a href="mailto:legal@imavenai.com">legal@imavenai.com</a>.</p>
                </Paragraph>
            </Section>

            <br />
            <br />

            <Section ref={privacyPolicyRef}>
                <Heading>Privacy Policy</Heading>
                <Paragraph>
                    <p><strong>Effective Date: [1.1.2024]</strong></p>

                    <p>Imaven ("we", "us", "our") values and respects your privacy. This Privacy Policy outlines our commitment to protecting your privacy and the procedures we follow to safeguard your personal information.</p>
                    <p>This Privacy Policy describes how we gather, use, disclose, and safeguard your personal information when you interact with our services. We encourage you to read this Privacy Policy carefully to ensure you understand its content.</p>

                    <h2>Information We Collect</h2>
                    <p>We gather personal data that you voluntarily provide to us during your use of our services. This data may include your name, email address, phone number, and other contact information. Additionally, as part of our service to help you make the correct product choice before buying, we collect and analyze your responses to our questions. While we store this information in our database and may use it for various purposes, we commit to not using your identifiable personal data without your consent.</p>

                    <h2>Use of Your Information</h2>
                    <p>We utilize your information to provide, maintain, and enhance our services; to engage with you about our services; to personalize your experience; and to send you promotional communications, including emails and requests for feedback.</p>

                    <h2>Information Sharing</h2>
                    <p>We may share your non-personal information with third-party service providers that facilitate our services, our affiliates, and business partners for marketing purposes, or as required by law. We also reserve the right to use aggregated or anonymized data as we see fit. For international data transfers, we ensure appropriate safeguards are in place to protect your personal information, complying with applicable U.S. laws and other global privacy regulations.</p>

                    <h2>Data Security</h2>
                    <p>We implement reasonable measures to safeguard your personal data from unauthorized access, use, or disclosure. However, no data transmission over the internet can be guaranteed to be entirely secure.</p>

                    <h2>Data Retention</h2>
                    <p>We retain your personal data as long as it's necessary to provide our services and for other vital purposes such as adhering to legal obligations, resolving disputes, and upholding our agreements.</p>

                    <h2>Your Rights</h2>
                    <p>You have certain rights in relation to your personal data, including the right to request access to, correction of, or deletion of your personal data. You also reserve the right to object to processing or to withdraw consent. We will address your request within a reasonable timeframe.</p>

                    <h2>Children's Privacy</h2>
                    <p>Our services are not intended for children below the age of 16, and we do not consciously gather personal data from children under 16.</p>

                    <h2>Third-Party Links</h2>
                    <p>Our services may incorporate links to other websites, which are not governed by this Privacy Policy.</p>

                    <h2>Privacy Policy Updates</h2>
                    <p>We may revise this policy periodically. In case of significant changes, we will notify you via our services or through other appropriate means.</p>

                    <h2>Data Protection Officer</h2>
                    <p>We have appointed a Data Protection Officer to oversee our data protection efforts. If you have any queries or concerns about our privacy practices, please get in touch with our Data Protection Officer at <a href="mailto:legal@imavenai.com">legal@imavenai.com</a>.</p>

                    <h2>Complaints</h2>
                    <p>If you have any grievances about how we manage your personal data, please contact us so we can resolve the issue. If we fail to address your complaint, you can contact the relevant Privacy Protection Authority.</p>

                    <p>By using our services, you consent to our collection, use, and sharing of your personal information as outlined in this Privacy Policy.</p></Paragraph>
            </Section>

            <br />
            <br />

            <Section ref={copyrightProtectionRef}>
                <Heading>Copyright Protection</Heading>
                <Paragraph>
                    <h2>1. Copyright Notice:</h2>
                    <ul>
                        <li>All content and materials on Imaven's website and platforms, including but not limited to text, graphics, logos, icons, images, audio clips, digital downloads, data compilations, and software, are the property of Imaven or its content suppliers and are protected by Israel and international copyright laws.</li>
                    </ul>

                    <h2>2. Copyright Ownership:</h2>
                    <ul>
                        <li>The compilation of all content on Imaven's services is the exclusive property of Imaven and protected by Israel and international copyright laws.</li>
                    </ul>

                    <h2>3. Use of Content:</h2>
                    <ul>
                        <li>The content and materials on Imaven's services may only be used as a shopping resource. Any other use, including the reproduction, modification, distribution, transmission, republication, display, or performance, of the content on this site is strictly prohibited.</li>
                    </ul>

                    <h2>4. Intellectual Property Rights:</h2>
                    <ul>
                        <li>All intellectual property rights in all materials or content provided as part of the Services shall remain at all times the property of Imaven or its licensors. You are not permitted to use such materials or content unless authorized by us or our licensors.</li>
                    </ul>

                    <h2>5. Unauthorized Use:</h2>
                    <ul>
                        <li>Unauthorized use of Imaven's content and materials may violate copyright and trademark laws and could result in civil and criminal penalties.</li>
                    </ul>

                    <h2>6. Infringement Claims:</h2>
                    <ul>
                        <li>Imaven respects the intellectual property rights of others. If you believe that your work has been copied in a way that constitutes copyright infringement, please contact us at <a href="mailto:legal@imavenai.com">legal@imavenai.com</a>.</li>
                    </ul>

                    <h2>7. Amendments:</h2>
                    <ul>
                        <li>Imaven reserves the right to make changes to this Copyright Protection notice. Any changes will be posted on the Imaven website and will take effect immediately.</li>
                    </ul>

                    <h3>Contact Information:</h3>
                    <ul>
                        <li>For any questions or concerns regarding this Copyright Protection, please contact us at <a href="mailto:legal@imavenai.com">legal@imavenai.com</a>.</li>
                    </ul>
                </Paragraph>
            </Section>

            <br />
            <br />

            <Section ref={disclaimersRef}>
                <Heading>Disclaimers</Heading>
                <Paragraph>
                    <h2>1. General Disclaimer:</h2>
                    <ul>
                        <li>The information provided by Imaven on our website and through our services is for general informational purposes only. All information on the site is provided in good faith, however, we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the site.</li>
                    </ul>

                    <h2>2. Professional Advice Disclaimer:</h2>
                    <ul>
                        <li>The services provided by Imaven are not a substitute for professional advice. All information and services are provided with the understanding that Imaven is not engaged in rendering legal, medical, counseling, or other professional services or advice. We encourage you to consult with appropriate professionals before taking any actions based on such information.</li>
                    </ul>

                    <h2>3. External Links Disclaimer:</h2>
                    <ul>
                        <li>Our services may contain links to external websites that are not provided or maintained by or in any way affiliated with Imaven. Please note that Imaven does not guarantee the accuracy, relevance, timeliness, or completeness of any information on these external websites.</li>
                    </ul>

                    <h2>4. Errors and Omissions Disclaimer:</h2>
                    <ul>
                        <li>While we strive to make the information provided by Imaven as accurate as possible, the dynamic nature of our industry and our reliance on third parties can result in some information having changed since it was published or having inaccuracies. Imaven is not responsible for any errors or omissions, or for the results obtained from the use of this information.</li>
                    </ul>

                    <h2>5. Fair Use Disclaimer:</h2>
                    <ul>
                        <li>Imaven may use copyrighted material in our content that has not always been specifically authorized by the copyright owner. We are making such material available in our efforts to advance understanding of our services and fields of interest.</li>
                    </ul>

                    <h2>6. No Endorsement Disclaimer:</h2>
                    <ul>
                        <li>References on our website and services to any products, services, processes, hypertext links to third parties, or other information by trade name, trademark, manufacturer, supplier, or otherwise does not constitute or imply its endorsement, sponsorship, or recommendation by Imaven.</li>
                    </ul>

                    <h2>7. Contact Information:</h2>
                    <ul>
                        <li>If you have any queries regarding any of our disclaimers, please contact us at <a href="mailto:legal@imavenai.com">legal@imavenai.com</a>.</li>
                    </ul>
                </Paragraph>
            </Section>

            <br />
            <br />

            <Section ref={cookieRef}>
                <Heading>Cookie</Heading>
                <Paragraph>

                    <h2>1. Introduction:</h2>
                    <ul>
                        <li>Imaven uses cookies on our website to enhance user experience, analyze our traffic, and provide customized services. This Cookie Policy explains what cookies are, how we use them, and how you can manage your preferences.</li>
                    </ul>

                    <h2>2. What are Cookies:</h2>
                    <ul>
                        <li>Cookies are small text files that are stored on your device (computer, mobile phone, tablet, etc.) by websites you visit. They are widely used to make websites work efficiently, as well as to provide information to the site owners.</li>
                    </ul>

                    <h2>3. Use of Cookies:</h2>
                    <ul>
                        <li>Imaven uses cookies to understand how you use our website and services, to enhance your user experience, and to personalize your relationship with Imaven, including providing you with more relevant advertising.</li>
                    </ul>

                    <h2>4. Types of Cookies Used:</h2>
                    <ul>
                        <li>Essential Cookies: These are necessary for the website to function and cannot be switched off in our systems.</li>
                        <li>Analytical/Performance Cookies: These allow us to recognize and count the number of visitors and see how visitors move around our website.</li>
                        <li>Functionality Cookies: These are used to recognize you when you return to our website, enabling us to personalize our content for you.</li>
                        <li>Targeting Cookies: These cookies record your visit to our website, the pages you have visited, and the links you have followed.</li>
                    </ul>

                    <h2>5. Managing Cookies:</h2>
                    <ul>
                        <li>You have the right to decide whether to accept or reject cookies. You can set or amend your web browser controls to accept or refuse cookies. If you choose to reject cookies, you may still use our website though your access to some functionality and areas of our website may be restricted.</li>
                    </ul>

                    <h2>6. Consent:</h2>
                    <ul>
                        <li>By using our website, you consent to the use of cookies as described in this Cookie Policy.</li>
                    </ul>

                    <h2>7. Changes to the Cookie Policy:</h2>
                    <ul>
                        <li>Imaven reserves the right to update this Cookie Policy at any time. We encourage you to periodically review this page for the latest information on our cookie practices.</li>
                    </ul>

                    <h2>8. Contact Information:</h2>
                    <ul>
                        <li>If you have any questions about our use of cookies or other technologies, please email us at <a href="mailto:legal@imavenai.com">legal@imavenai.com</a>.</li>
                    </ul>
                </Paragraph>
            </Section>

            <br />
            <br />

            <Section ref={accessibilityComplianceStatementRef}>
                <Heading>Accessibility Compliance Statement</Heading>
                <Paragraph>
                    <h2>1. Commitment to Accessibility:</h2>
                    <ul>
                        <li>Imaven is committed to ensuring digital accessibility for people with disabilities. We are continually improving the user experience for everyone and applying the relevant accessibility standards.</li>
                    </ul>

                    <h2>2. Conformance Status:</h2>
                    <ul>
                        <li>We aim for our web content to be compliant with the Web Content Accessibility Guidelines (WCAG) 2.1, Level AA, and are actively working to achieve this level of accessibility.</li>
                    </ul>

                    <h2>3. Efforts to Support Accessibility:</h2>
                    <ul>
                        <li>Imaven takes the following measures to ensure accessibility:
                            <ul>
                                <li>Including accessibility as part of our mission statement.</li>
                                <li>Integrating accessibility into our procurement practices.</li>
                                <li>Providing continual accessibility training for our staff.</li>
                                <li>Including people with disabilities in our design personas.</li>
                            </ul>
                        </li>
                    </ul>

                    <h2>4. Feedback:</h2>
                    <ul>
                        <li>We welcome your feedback on the accessibility of Imaven's website and services. Please let us know if you encounter any barriers to access by emailing us at <a href="mailto:legal@imavenai.com">legal@imavenai.com</a>.</li>
                    </ul>

                    <h2>5. Technical Specifications:</h2>
                    <ul>
                        <li>Accessibility of Imaven’s website relies on the following technologies to work with the particular combination of web browser and any assistive technologies or plugins installed on your computer:
                            <ul>
                                <li>HTML</li>
                                <li>WAI-ARIA</li>
                                <li>CSS</li>
                                <li>JavaScript</li>
                            </ul>
                        </li>
                    </ul>

                    <h2>6. Limitations and Alternatives:</h2>
                    <ul>
                        <li>Despite our best efforts to ensure accessibility of Imaven’s website, there may be some limitations. Please contact us if you observe an issue.</li>
                    </ul>

                    <h2>7. Assessment Approach:</h2>
                    <ul>
                        <li>Imaven assessed the accessibility of our website by the following approaches:
                            <ul>
                                <li>Self-evaluation</li>
                                <li>External evaluation</li>
                            </ul>
                        </li>
                    </ul>

                    <h2>8. Review and Update:</h2>
                    <ul>
                        <li>This statement is reviewed and updated regularly. Last assessment date: 1.1.2024</li>
                    </ul>

                    <h3>Contact Information:</h3>
                    <ul>
                        <li>For questions or assistance regarding this Accessibility Compliance Statement, please contact us at <a href="mailto:legal@imavenai.com">legal@imavenai.com</a>.</li>
                    </ul>
                </Paragraph>
            </Section>

        </div>
    </div>);
}

export default About;
