import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import Image1 from '../../assets/choice-image1.svg';
import Image from '../../assets/chocie-image.svg';
import ImavenRadio from '../Helpers/imaven-radio';
import useAuthenticatedAxios from '../../interceptor/AuthenticatedAxios';
import RandomImage from '../Questions/random-image'
import { useConversation } from '../../providers/ConversationContext';
import toast from 'react-hot-toast';

const Title = styled.span`
    color: #323233;
    text-align: justify;
    font-family: Qanelas Soft;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.48px;
    width: 343px;
    flex-shrink: 0;
`;

const BlueBtn = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.3rem 1rem 0.3rem 1rem;
    background-color: #499EDC;
    color: #FFFFFF;
    border: none;
    border-radius: 30px;
    font-size: 0.8rem;
    height:80px;
    box-shadow: 1px 1px 8px rgba(86, 204, 242, 0.1);
    width: 130px;
    float:right;
    transition: background-color 0.2s ease-in-out;
`;

const GrayBtn = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.3rem 1rem 0.3rem 1rem;
    background-color: gray;
    color: #FFFFFF;
    border: none;
    border-radius: 30px;
    font-size: 0.8rem;
    height:80px;
    box-shadow: 1px 1px 8px rgba(86, 204, 242, 0.1);
    width: 130px;
    float:right;
    transition: background-color 0.2s ease-in-out;
`;

const NextBtn = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.3rem 1rem 0.3rem 1rem;
    background-color: #499EDC;
    color: #FFFFFF;
    border: none;
    border-radius: 30px;
    font-size: 0.8rem;
    height:45px;
    box-shadow: 1px 1px 8px rgba(86, 204, 242, 0.1);
    width: 100%; // 100% of the parent width
    float:right;
    transition: background-color 0.2s ease-in-out;
`;

const AskForChoice = ({ showQuestions }) => {
    const authenticatedAxios = useAuthenticatedAxios();
    const { conversationId, setConversationId } = useConversation();

    const [showThanksPage, setThankPage] = useState(false);
    const [selectedRadio, setSelectedRadio] = useState("True");
    const op = [{ value: "True", label: "Yes" }, { value: "No", label: "No" }];
    const [specifyChange, setSpecifyChange] = useState("");

    const handleOptionChange = (val) => {
        setSelectedRadio(val);
    }

    const handleSpecifyChange = (val) => {
        setSpecifyChange(val);
    }

    const iWantHuman = async () => {
        try {
            const response = await authenticatedAxios.post('/userChoice', { choice: true, conversationId: conversationId });
            if (response.status >= 200 && response.status < 300) {
                console.log('Data fetched successfully:', response.data);

            } else {
                toast.error(response.data || "An error occurred");
                console.error('Unexpected status code:', response.status);
            }
        } catch (error) {
            toast.error(error.message || "An error occurred");
            console.error('Error fetching data:', error);
        }

        setThankPage(true);
    }

    const procced = () => {
        showQuestions(true)
    }

    return (
        <div>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly", height: "70vh", alignItems: 'center' }}>
                <div style={{
                    display: "flex", justifyContent: "center"
                }}>
                    <div style={{ width: "50vw" }}>
                        <RandomImage questionType={'ask'} />
                    </div>
                    {/* <img src={showThanksPage ? Image : Image1} /> */}
                </div>
                {showThanksPage ? <div>
                    <div style={{
                        display: "flex", flexDirection: "column", height: "200px",
                        justifyContent: "space-between", marginTop: "20px"
                    }}>
                        <Title style={{ textAlign: "center" }}>Coming soon…</Title>
                        <Title style={{ textAlign: "center" }}>
                            Thank you for choosing to consult with a professional human advisor. This premium feature is currently in the final stages of development and will be released soon.
                        </Title>
                        <Title style={{ textAlign: "center", fontWeight: 'bold' }}>
                            In the meantime, our advanced AI system will provide you with free expert advice.
                        </Title>
                    </div>
                    <div style={{ marginTop: "80px" }}>
                        <NextBtn onClick={procced}>Next</NextBtn>
                    </div>
                </div> :
                    <div>
                        <div style={{
                            display: "flex", flexDirection: "column",
                            justifyContent: "space-between", marginTop: "20px"
                        }}>
                            <Title style={{ textAlign: "center", fontWeight: "800" }}>
                            Prefer a personal touch to your product recommendations? Interested in insights from our expert human advisors? We've got you covered. 
                            Upgrade now to receive personalized advice from our experienced product specialists.</Title>
                            <br />
                            <Title style={{ textAlign: "center" }}>
                                <strong>Special launch offer:</strong> Human advise for only $2 (instead of $10 regular price)
                            </Title>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-around", marginTop: "20px" }}>
                            <BlueBtn onClick={iWantHuman}>
                                I want a human advisor ($2)
                            </BlueBtn>
                            <GrayBtn onClick={procced}>
                                I want the free AI advisor
                            </GrayBtn>
                        </div>
                    </div>}
            </div>
        </div>

    )
}

export default AskForChoice;