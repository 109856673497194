import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ImavenBreadcrumbs from '../Helpers/imaven-breadcrumbs';
import Question from './question';
import { useNavigate, useLocation } from 'react-router-dom';
import ImavenLoading from '../Helpers/imaven-loading-icon'
import useAuthenticatedAxios from '../../interceptor/AuthenticatedAxios';
import { useConversation } from '../../providers/ConversationContext';
import AskForChoice from './ask-for-choice'
import toast from 'react-hot-toast';
import HamburgerButton from '../Helpers/hamburger-button';
import Sidebar from '../Layout/Side-Menu';
import Cookies from 'js-cookie';

const QuestionContiner = styled.div`
    // display:flex;
    // justify-content:center;
    // flex-direction:column;
    // align-items:center;
`;

const TopPage = styled.div`
    background: linear-gradient(180deg, #01BDFB 0%, #0382F6 100%);
    height:10vh;
    width:100%;
    display:flex;
    justify-content:space-around;
    align-items:center;
`;

const TitleSpan = styled.span`
    color: #FFF;
    text-align: center;
    font-family: Varela Round;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
`;

const LoadingScreen = styled.div`
    position:fixed;
    z-index:1000;
    height:100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;


const QuestionGenerator = () => {
    const authenticatedAxios = useAuthenticatedAxios();

    const { state } = useLocation();
    const { txt } = state;
    const [currentQ, setcurrentQValue] = useState(txt.response);
    const [messageId, setMessageId] = useState(txt.messageId);
    const [loading, setLoading] = useState(false);

    const [numbers, setNumbers] = useState([1, 2, 3]);
    const [currentNumber, setCurrentNumber] = useState(1);
    const { conversationId, setConversationId } = useConversation();

    const [isOpen, setIsOpen] = useState(false);

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };
    const navigate = useNavigate();

    useEffect(() => {
        // Function to handle the beforeunload event
        const handleBeforeUnload = (e) => {
                const confirmationMessage = 'It looks like you have been editing something. If you leave before saving, your changes will be lost.';
                e.returnValue = confirmationMessage; // For most browsers
                return confirmationMessage; // For some older browsers
        };

        // Adding event listener for beforeunload
        window.addEventListener('beforeunload', handleBeforeUnload);

        // Cleanup function to remove the event listener
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []); 
    useEffect(()=>{
      let conversationCoockieId = Cookies.get('conversationId');
      if(!conversationId){
        navigate('/startchat');
      }
      if(!conversationId &&conversationCoockieId){
        setConversationId(conversationCoockieId);
      }
      else if(!conversationId &&!conversationCoockieId){
        navigate('/startchat');
      }

    },[])

    const nextQuestion = async (answer) => {
        setLoading(true);
        //setcurrentQValue("");
        try {
            const response = await authenticatedAxios.post('/chat', { content: answer, conversationId: conversationId });
            if (response.status >= 200 && response.status < 300) {
                setcurrentQValue("");
                console.log('Data fetched successfully:', response.data);
                setcurrentQValue(response.data.response);
                setMessageId(response.data.messageId);
                setCurrentNumber(currentNumber + 1)
                setNumbers([...numbers, numbers.length + 1])
                setLoading(false);

            } else {
                toast.error(response.data || "An error occurred");
                console.error('Unexpected status code:', response.status);
                setLoading(false);
            }
        } catch (error) {
            toast.error(error.message || "An error occurred");
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    }

    // Utility function to find numeric values representing min/max
    function extractMinMax(content) {
        const numbers = content.match(/\b\d+\b/g);
        return numbers ? [Math.min(...numbers.map(Number)), Math.max(...numbers.map(Number))] : ['none', 'none'];
    }

    function extractDates(content) {
        // Placeholder for dates extraction logic
        return ['none', 'none'];
    }

    function extractTimes(content) {
        // Placeholder for times extraction logic
        return ['none', 'none'];
    }

    function extractDuration(content) {
        // Placeholder for duration extraction logic
        return ['none', 'none'];
    }

    function parseQuestionInput(text) {
        // Utility functions for extracting ranges, dates, etc. (to be defined elsewhere)
        function extractMinMax(content) { /* ... */ }
        function extractDates(content) { /* ... */ }
        function extractTimes(content) { /* ... */ }
        function extractDuration(content) { /* ... */ }

        // Extract qtype using regex pattern
        const qtypePattern = /\[qtype: ?([\w\-/]+)\]/i;
        const qtypeMatch = text.match(qtypePattern);
        const qtype = qtypeMatch ? qtypeMatch[1].toLowerCase().replace(/[_\- ]/g, "") : 'none';

        // Separate the text into pre-qtype (header), qtype, and post-qtype (content)
        const splitText = text.split(qtypePattern);
        const header = splitText.length > 1 && splitText[0].trim() ? splitText[0].trim() : 'none';
        let postQtypeContent = splitText.length > 2 ? splitText[2].trim() : 'none';

        // Split the post-qtype content into lines and filter out empty lines
        const contentLines = postQtypeContent.split('\n').filter(line => line.trim() !== '');

        // The content is the first non-empty line after the qtype
        const content = contentLines.shift(); // Removes and returns the first element

        // Original options pattern
        const optionsPattern = /^\s*[\d\w*-][.)]?\s+(.+)/;

        let options = [];
        let footer = 'none';
        let isFooterStarted = false;

        contentLines.forEach(line => {
            if (line.includes("Remember that exact prices will depend")) {
                isFooterStarted = true;
            }

            if (!isFooterStarted) {
                const match = line.match(optionsPattern);
                if (match) {
                    options.push(match[1].trim());
                }
            } else {
                if (footer === 'none') {
                    footer = line.trim();
                } else {
                    footer += '\n' + line.trim();
                }
            }
        });


        // Variables for extracted values
        let [min, max] = ['none', 'none'];
        let [startDate, endDate] = ['none', 'none'];
        let [startTime, endTime] = ['none', 'none'];
        let [hours, minutes] = ['none', 'none'];

        // Extract ranges or other values based on qtype
        if (qtype.includes('range') || qtype.includes('slider') || qtype.includes('rating') || qtype.includes('numberinput')) {
            let minMax = extractMinMax(content);
            if (minMax && minMax.length === 2) {
                [min, max] = minMax;
            } else {
                // Handle the error or assign default values
                [min, max] = ['none', 'none'];
                [min, max] = ['0', '1000000'];
            }
        }
        if (qtype.includes('daterangepicker')) {
            [startDate, endDate] = extractDates(content);
        }
        if (qtype.includes('timerange')) {
            [startTime, endTime] = extractTimes(content);
        }
        if (qtype.includes('durationpicker')) {
            [hours, minutes] = extractDuration(content);
        }

        // Return the extracted data
        return {
            header: header,
            qtype: qtype,
            content: content,
            options: options,
            footer: footer,
            min: min,
            max: max,
            startDate: startDate,
            endDate: endDate,
            startTime: startTime,
            endTime: endTime,
            hours: hours,
            minutes: minutes,
            messageId: messageId
        };
    }

    const [showChoice, setShowChoice] = useState(true);

    const showQuestions = (val) => {
        setShowChoice(false);
    }

    return (
        <div>
            {loading ?
                (<LoadingScreen >
                    <ImavenLoading type={'middle'} />
                </LoadingScreen>) : <div />
            }
            {isOpen && <Sidebar close={toggleSidebar} />}
            <QuestionContiner>
                <TopPage>
                    <HamburgerButton isOpen={isOpen} toggle={toggleSidebar} ></HamburgerButton>
                    <TitleSpan>IMAVEN </TitleSpan>
                    <div> &nbsp;</div>
                </TopPage>
                <ImavenBreadcrumbs numbers={numbers} initialIndex={currentNumber} disablePrev={true} disableNext={true}></ImavenBreadcrumbs>
                {showChoice ? <AskForChoice showQuestions={showQuestions} /> :
                    <div style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-around" }}>
                        <Question Question={currentQ} nextQ={nextQuestion}></Question>
                    </div>
                }
            </QuestionContiner>
        </div>
    );
}

export default QuestionGenerator;