import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { config } from '../config';
const apiClient = axios.create({
  baseURL: config.gate_way_url, //move to env
  timeout: 350000,
  headers: {
    'Content-Type': 'application/json',
  },
});

function useAuthenticatedAxios() {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  apiClient.interceptors.request.use(
    async (config) => {
      const token = await getAccessTokenSilently();
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  apiClient.interceptors.response.use(

    (response) => {
      return response;
    },
    async (error) => {
      console.log(error)      // If error response code is 401 or 403, we redirect to login
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        await loginWithRedirect();
      } else if (error.response.status === 400) {
        //TODO move to not allowed page
      }
      return Promise.reject(error);
    }
  );

  return apiClient;
}

export default useAuthenticatedAxios;
