import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import useAuthenticatedAxios from '../../interceptor/AuthenticatedAxios';
import ImavenLoading from '../Helpers/imaven-loading-icon'
import toast from 'react-hot-toast';
import { useConversation } from '../../providers/ConversationContext';

import FindStoreImg from '../../assets/find-store.svg'
import TwitterShare from '../../assets/twitter-share.svg'
import LinkShare from '../../assets/link-share.svg'
import FacebookShare from '../../assets/facebook-share.svg'
import QuestionMark from '../../assets/recommendation/question.svg';
import ArrowIcon from '../../assets/arrow.svg';
import ArrowWhiteIcon from '../../assets/arrow-white.svg'

import BlueHeart from '../../assets/blue heart.svg'
import { useNavigate } from 'react-router-dom';
import RandomImage from './random-image';

import ImavenModal from '../Helpers/imaven-modal';
import { message } from 'antd';

const LoadingScreen = styled.div`
    position:fixed;
    z-index:1000;
    height:100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;

const TopPage = styled.div`
    background: linear-gradient(180deg, #01BDFB 0%, #0382F6 100%);
    height:10vh;
    width:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction: column;
`;

const TitleSpan = styled.span`
    color: #FFF;
    text-align: center;
    font-family: Varela Round;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
`;

const TopTitle = styled.div`
    display:flex;
    justify-content:space-between;
    padding-top: 2vh;
    align-items:center;
`
const Title = styled.span`
    color: #009BF3;
    font-family: Qanelas Soft;
    font-size: 30px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 0.03em;
    text-align: left;

`;

const SubTitle = styled.span`
    font-family: Qanelas Soft;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.03em;
    text-align: left;
`;

const BackgroundAboutProduct = styled.span`
    font-family: Qanelas Soft;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.03em;
    text-align: left;
`;

const ProductCard = styled.div`
    box-shadow: 1px 1px 8px 0px #0000001A;
    padding-left: 3vw;
    padding-right: 3vw;
    padding-top: 1vw;
    padding-bottom: 2vh;
    margin-bottom:2vh;
    margin-top:2vh;
    border-radius: 0px 0px 15px 15px;
`;

const GenricButtonShape = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    font-family: Qanelas Soft;
    border-radius: 30px;
    font-size: 16px;
    font-weight: 500;
    height:100%;
    width: 100%;

    ${props => `
        background-color: ${props.color};
    `}
`;

const PriceTxt = styled.span`
    font-family: Qanelas Soft;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0.03em;
    text-align: center;
`;

const ProductInfo = styled.span`
    font-family: Qanelas Soft;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.03em;
    text-align: left;
`;

const NavigateToStore = styled.span`
    font-family: Qanelas Soft;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.03em;
    text-align: left;
`;

const FindLocationBtn = styled.span`
    font-family: Qanelas Soft;
    font-size: 16px;
    font-style: italic;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #009BF3;
`;

const LinkButton = styled.div`
    box-shadow: 1px 1px 8px 0px #0000001A;
    border-radius: 50%;
    width:45px;
    height:45px;
    display:flex;
    justify-content:center;
    align-items:center;
`;

const LikeBtn = styled.div`
    box-shadow: 1px 1px 8px 0px #0000001A;
    border-radius: 50%;
    width:45px;
    height:45px;
    display:flex;
    justify-content:center;
    align-items:center;

    ${props => props.disabled && `
        opacity: 0.3;
        cursor: not-allowed;
        pointer-events: none;`}
`;

const ShareIconWrapper = styled.div`
    display:flex;
    justify-content: space-between;
    width: 26vw;
`;

const ShareIcon = styled.img`
    width: 28px;
    height: 28px;
`;

const Square = styled.div`
  min-height: 150px;
  margin: 8px;
  background-color: white;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius:15px;
  fill: #FFF;
  filter: drop-shadow(1px 1px 8px rgba(0, 0, 0, 0.10));
`;

const ModalTxt = styled.span`
    color: #333;
    font-family: Qanelas Soft;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.48px;
`;

const ProductName = ({ data }) => (
    <div>
        <div>
            <span>
                <b>Product Name</b>
            </span>
        </div>
        <div>
            <span>
                {data.productName}
            </span>
        </div>
    </div>
);

const CriteriaExtraction = ({ data }) => (
    <div>
        <div>
            <span>
                <b>Criteria Extraction</b>
            </span>
        </div>
        <div>
            <ul>
                <li>Product Category: {data.productCategory}</li>
                <li>Budget Range: {data.budgetRange}</li>
                <li>Essential Fetures or Specifications: {data.essentialFeatures}</li>
                <li>Brand Prefernces: {data.brandPreferences}</li>
                <li>Unique or specific user requirement: {data.uniqueUserRequirements}</li>
            </ul>
        </div>
    </div>
);

const MarketResearchAndAnalysis = ({ data }) => (
    <div>
        <div>
            <span>
                <b>Market Research and Analysis</b>
            </span>
        </div>
        <div>
            <span>
                {data.details}
            </span>
        </div>
    </div>
);

const PreciseProductSelection = ({ data }) => (
    <div>
        <div>
            <span>
                <b>Precise Product Selection</b>
            </span>
        </div>
        <div>
            <span>
                {data.selectedProduct}
            </span>
            <span>
                {data.selectionReason}
            </span>
        </div>
    </div>
);

const DetailedRecommendation = ({ data }) => (
    <div>
        <div>
            <span>
                <b>Detailed Recommendation</b>
            </span>
        </div>
        <div>
            <span>
                {data.productName}
            </span>
            <span>
                {data.productDetails}
            </span>
        </div>
    </div>
);

const JustificationOfSelection = ({ data }) => (
    <div>
        <div>
            <span>
                <b>Justification of Selection</b>
            </span>
        </div>
        <div>
            <span>
                {data.uniqueSellingPoints}
            </span>
            <span>
                {data.customerSatisfaction}
            </span>
        </div>
    </div>
);

const ConclusiveSummary = ({ data }) => (
    <div>
        <div>
            <span>
                <b>Conclusive Summary</b>
            </span>
        </div>
        <div>
            <span>
                {data.summary}
            </span>
        </div>
    </div>
);

const ProductImage = ({ url }) => {
    const d = {
        backgroundImage: `url(${url})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: '200px'
    }
    return < div style={d}></div >
};

const SquareComponent = ({ d }) => {
    const [isOpen, setOpen] = useState(false);
    const toggleOpen = () => setOpen(!isOpen);

    const renderLayout = () => {
        if (!isOpen) return <div style={{ padding: "5vw 1vh" }}>
            <ProductName data={d.detailedRecommendation} />
            <br />
            <br />
            <ProductImage url={d.imageUrl[0]}/>
            <br />
            <br />
            <CriteriaExtraction data={d.criteriaExtraction} />
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                <img src={ArrowIcon} />
            </div>
        </div>;

        return <div style={{ padding: "5vw 1vh" }}>
            <ProductName data={d.detailedRecommendation} />
            <br />
            <br />
            <ProductImage url={d.imageUrl[0]}/>
            <br />
            <br />
            <CriteriaExtraction data={d.criteriaExtraction} />
            <br />
            <br />
            <MarketResearchAndAnalysis data={d.marketResearchAndAnalysis} />
            <br />
            <br />
            <PreciseProductSelection data={d.preciseProductSelection} />
            <br />
            <br />
            <DetailedRecommendation data={d.detailedRecommendation} />
            <br />
            <br />
            <JustificationOfSelection data={d.justificationOfSelection} />
            <br />
            <br />
            <ConclusiveSummary data={d.conclusiveSummary} />
            <br />
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                <img src={ArrowIcon} style={{ transform: 'rotate(-90deg)' }} />
            </div>
        </div>
    };

    return (
        <Square onClick={toggleOpen}>
            {renderLayout()}
        </Square>
    );
};

const Summary = () => {
    const navigate = useNavigate();
    const authenticatedAxios = useAuthenticatedAxios();
    const { conversationId } = useConversation();
    const [isModalOpen, setModalOpen] = useState(false);
    const [likeStat, setLikeStat] = useState(false);
    const [message, setMessage] = useState({
        "initialHighlight": {
            "type": "",
            "details": ""
        },
        "outputFormatting": {
            "readability": ""
        },
        "summaryReview": {
            "productType": "",
            "budgetLimits": "",
            "desiredFeatures": "",
            "brandPreferences": "",
            "specificUserRequirements": ""
        },
        "criteriaExtraction": {
            "productCategory": "",
            "budgetRange": "",
            "essentialFeatures": "",
            "brandPreferences": "",
            "uniqueUserRequirements": ""
        },
        "marketResearchAndAnalysis": {
            "details": ""
        },
        "preciseProductSelection": {
            "selectedProduct": "",
            "selectionReason": ""
        },
        "detailedRecommendation": {
            "productName": "",
            "productDetails": ""
        },
        "justificationOfSelection": {
            "uniqueSellingPoints": "",
            "customerSatisfaction": ""
        },
        "conclusiveSummary": {
            "summary": ""
        },
        "optionalFeedbackRequest": {
            "feedback": ""
        },
        "enhancedDatabaseInformation": {
            "product": "",
            "recommendedModel": "",
            "category": "",
            "subCategory": "",
            "subSubCategory": ""
        },
        "link": "",
        "imageUrl": [""]
    });
    const [loading, setLoading] = useState(true);
    // At the top level of the Summary component
    const { state } = useLocation();
    let txt = state.txt;

    useEffect(() => {
        const sonicChat = async () => {
            try {
                const response = await authenticatedAxios.post('/recommendation', { content: txt, conversationId });
                if (response.status >= 200 && response.status < 300) {
                    setMessage(response.data);
                    console.log(response.data)
                    setLoading(false);
                } else {
                    toast.error(response.data || "An error occurred");
                    console.error('Unexpected status code:', response.status);
                    setLoading(false);
                }
            } catch (error) {
                toast.error(error.message || "An error occurred");
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        }

        sonicChat();
    }, []);

    const anotherAdvice = () => {
        navigate('/startchat', { replace: true })
    }

    const shareLink = () => {
        navigator.share({ title: "Share link to app", url: "https://imavenai.com/", text: "Hey! I've been using iMaven for smart product recommendations and it's totally transformed the way I shop. With expert, AI-enhanced advice, iMaven helps me find exactly what I'm looking for, no matter what product I need. Thought you might enjoy it as well! Check it out here: https://imavenai.com/. #SmartShopping #iMavenAI" })
    };

    const LikeResult = async () => {
        try {
            setLikeStat(true);
            const response = await authenticatedAxios.post('/like', { conversationId: conversationId });
            if (response.status >= 200 && response.status < 300) {
                toast.success("Liked the result", { duration: "1000" });
            } else {
                console.error('Unexpected status code:', response.status);
                setLikeStat(false);
            }
        } catch (error) {
            toast.error(error.message || "An error occurred");
            setLikeStat(false);
        }
    };

    const buyOnAmazon = () => {
        window.open(message.link, '_blank', 'noopener,noreferrer');
    }

    const shareOnTwitter = () => {
        const link = "https://twitter.com/intent/tweet?url=https://www.imavenai.com&text=" + `Hey there! I've been using IMaven for my shopping and I'm loving it! It gives me AI-powered recommendations that are just spot on. It's totally changed how I shop for everything. Thought you might like to give it a try too! Check it out:` + `&hashtags=IMaven`;
        window.open(link, '_blank', 'noopener,noreferrer')
    }

    const shareOnFacebook = () => {
        const link = "https://www.facebook.com/sharer/sharer.php?u=www.imavenai.com&t=" + `Hey there`;
        window.open(link, '_blank', 'noopener,noreferrer')
    }


    return (
        <div>
            {loading ?
                (<LoadingScreen >
                    <ImavenLoading type={'last'} />
                </LoadingScreen>) : <div />}
            <TopPage>
                <TitleSpan>IMAVEN </TitleSpan>
            </TopPage>
            <div style={{ paddingLeft: "3vw", paddingRight: "3vw" }}>
                <TopTitle>
                    <div>
                        {/* <Title>Result</Title> */}
                    </div>
                    <ShareIconWrapper>
                        <ShareIcon onClick={shareLink} src={LinkShare} />
                        <ShareIcon src={FacebookShare} onClick={shareOnFacebook} />
                        <ShareIcon src={TwitterShare} onClick={shareOnTwitter} />
                    </ShareIconWrapper>
                </TopTitle>

                <ProductCard>
                    {/* image */}
                    <div style={{ marginTop: '5vw', width: "100%" }}>
                        <RandomImage questionType={'recommendation'} />
                        <div style={{ marginTop: '5vw' }}></div>
                    </div>

                    <div style={{ marginTop: '5vh', padding: "1vh 2vw " }}>
                        <span>
                            We're excited to present you with our top product recommendation,
                            selected especially for you! Our system prides itself on providing unbiased,
                            personalized suggestions based on your preferences and needs.
                        </span>
                    </div>

                    <SquareComponent d={message} />

                    <div style={{
                        height: "5vh", width: "100%",
                        display: 'flex', justifyContent: 'center', marginTop: '3.89vh', alignItems: 'center'
                    }}>
                        <div style={{ width: "55%", height: "100%" }}>
                            <GenricButtonShape color={'#55C34E'} onClick={buyOnAmazon}>
                                <PriceTxt style={{ marginRight: "4.98vw" }}>Buy On Amazon</PriceTxt>
                                <img src={ArrowWhiteIcon} />
                            </GenricButtonShape>
                        </div>
                        <div style={{ display: 'flex', height: '100%', alignItems: 'flex-start' }}>
                            <ShareIcon src={QuestionMark} onClick={() => setModalOpen(true)} />
                        </div>
                        <ImavenModal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
                            {/* You can put any HTML content here */}
                            <ModalTxt>
                                For your convenience, we've included a link to purchase this product on Amazon. However, this is merely a suggestion, not an obligation. We encourage you to explore and buy this product from any retailer that best suits your requirements and preferences. Whether it's Amazon or another store, the choice is entirely yours. Happy shopping!
                            </ModalTxt>
                        </ImavenModal>
                    </div>

                    {/* another advice btn & click */}
                    <div style={{ marginTop: '8vw', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                        <div style={{ width: "80%", height: '45px' }}>
                            <GenricButtonShape color={'#009BF3'} onClick={anotherAdvice}>
                                <PriceTxt>Find Another Product</PriceTxt>
                            </GenricButtonShape>
                        </div>
                        <LikeBtn disabled={likeStat} onClick={LikeResult}>
                            <img src={BlueHeart} />
                        </LikeBtn>
                    </div>

                </ProductCard>

            </div>


        </div>
    );
}

export default Summary;